import React, { useEffect } from 'react';

import { Formik } from 'formik';
import { func, number, shape, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';

import { generalAllocationCode, interests } from '../../../../../../api/holding/codes';
import {
  ConfirmationPopover,
  FormikCurrencyInput,
  FormikSelectField,
  ValidatedFormikField,
} from '../../../../../../components/common';
import { getBusinessDate, getDateInTimeFrameFrom, sameDayAsToday, today } from '../../../../../../date';
import { couponFormat, toCurrency, toNumberFormat } from '../../../../../../format-numbers';
import toFloat from '../../../../../../parse-float';
import { ImperiumIntlProvider } from '../../../../../intl-provider';
import { splitTenor } from '../../../../../Tenors/TenorItem';

export const buildInitialFormValues = ({ time, unit, issuer }) => ({
  principal: '',
  interestPaid: unit === 'M' && time <= 12 ? 'Z' : '',
  allocationCode: generalAllocationCode.code,
  bankIssuerId: issuer?.id || '',
});

const buildValidationSchema = ({ intl, unsolicitedRatesheetMaxBankAmount, hasSeparationOfDuties }) => {
  const baseSchema = {
    interestPaid: Yup.string().required(intl.formatMessage({ id: 'required' })),
    allocationCode: Yup.string().required(intl.formatMessage({ id: 'required' })),
    principal: Yup.string().required(intl.formatMessage({ id: 'required' })),
    bankIssuerId: Yup.string().required(intl.formatMessage({ id: 'required' })),
  };

  if (!hasSeparationOfDuties && unsolicitedRatesheetMaxBankAmount > 0) {
    baseSchema.principal = baseSchema.principal.test(
      'maxPrincipal',
      intl.formatMessage({ id: 'unsolicitedRatesheetMaxAmountReached' }),
      (value) => toFloat(value) <= unsolicitedRatesheetMaxBankAmount,
    );
  }

  return Yup.object().shape(baseSchema);
};

export const CreateTradeFromRatePopoverForm = injectIntl(
  ({
    allocationCodes,
    onAddAllocationCode,
    tenor,
    rate,
    issuer,
    onCancelClick,
    onCreateTradeSubmit,
    popoverContainer,
    onCreateRfqClick,
    unsolicitedRatesheetMaxBankAmount,
    hasSeparationOfDuties,
    intl,
    ratesheet,
    children,
  }) => {
    const { time, unit } = splitTenor(tenor);

    const tenorMaturityDate = getBusinessDate({
      date: getDateInTimeFrameFrom(today(), time, unit),
    }).toDate();

    const isStale = ratesheet && !sameDayAsToday(ratesheet.updatedAt);

    const isDealDisabled =
      isStale ||
      unsolicitedRatesheetMaxBankAmount === 0 ||
      unsolicitedRatesheetMaxBankAmount === null ||
      unsolicitedRatesheetMaxBankAmount === undefined ||
      hasSeparationOfDuties;

    return (
      <ImperiumIntlProvider>
        <Formik
          validationSchema={buildValidationSchema({ intl, unsolicitedRatesheetMaxBankAmount, hasSeparationOfDuties })}
          onSubmit={onCreateTradeSubmit}
          initialValues={buildInitialFormValues({ time, unit, issuer })}
        >
          {(formikProps) => {
            const { errors, touched, isSubmitting, dirty, isValid, handleSubmit, values, setFieldValue } = formikProps;
            const isDisabled = isSubmitting || !dirty || !isValid;
            const isRequestRfqDisabled =
              !values.principal || !values.interestPaid || !values.allocationCode || !values.bankIssuerId;

            useEffect(() => {
              if (issuer?.id && values.bankIssuerId !== issuer.id) {
                setFieldValue('bankIssuerId', issuer.id);
              }
            }, [values, setFieldValue]);

            return (
              <Column element="section" role="region" aria-label="new funds form">
                <Column>
                  <ValidatedFormikField
                    aria-label="principal"
                    autoFocus
                    name="principal"
                    labelId="principal"
                    component={FormikCurrencyInput}
                    touched={touched}
                    errors={errors}
                    allowNegative={false}
                  />
                  <ValidatedFormikField
                    name="interestPaid"
                    labelId="selectInterestPaid"
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={interests.map(({ code: value, label: id }) => ({
                      value,
                      label: intl.formatMessage({ id }),
                    }))}
                  />
                  <ValidatedFormikField
                    name="allocationCode"
                    labelId="holdingAllocationCode"
                    allowCreate
                    onChange={(value) => {
                      if (allocationCodes.find((option) => option.value === value)) {
                        return;
                      }

                      onAddAllocationCode([
                        ...allocationCodes,
                        {
                          value,
                          label: value,
                        },
                      ]);
                    }}
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={allocationCodes}
                  />
                  {children && children(formikProps)}
                </Column>
                <Row className="actions" justifyContentEnd>
                  {isDealDisabled ? null : (
                    <ConfirmationPopover
                      buttonLabelId="unsolicitedRatesheetDeal"
                      confirmationMessageId="unsolicitedRatesheetDealConfirmation"
                      confirmationMessageValues={{
                        principal: toCurrency({ value: toFloat(values.principal) }),
                        rate: toNumberFormat({ value: rate, ...couponFormat }),
                        maturityDate: tenorMaturityDate,
                        b: (msg) => <b>{msg}</b>,
                        attention: (msg) => <b className="attention">{msg}</b>,
                      }}
                      disabled={isDisabled}
                      onConfirm={handleSubmit}
                      className="btn-solid-primary"
                      popoverClass="dark"
                      isConfirmingFromOutside={isSubmitting}
                      container={popoverContainer?.current}
                    />
                  )}
                  <ConfirmationPopover
                    buttonLabelId="unsolicitedRatesheetRequestRfq"
                    confirmationMessageId="areYouSure"
                    popoverClass="dark"
                    className="btn-solid-primary"
                    disabled={isRequestRfqDisabled}
                    container={popoverContainer?.current}
                    onConfirm={() => {
                      onCreateRfqClick({
                        issuerId: issuer.id,
                        maturities: [
                          {
                            time,
                            unit,
                          },
                        ],
                        ...values,
                      });
                    }}
                  />
                  <Button onClick={onCancelClick}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Row>
              </Column>
            );
          }}
        </Formik>
      </ImperiumIntlProvider>
    );
  },
);

CreateTradeFromRatePopoverForm.propTypes = {
  onCreateTradeSubmit: func,
  onCancelClick: func,
  onAddAllocationCode: func,
  popoverContainer: shape(),
  tenor: string,
  rate: number,
  unsolicitedRatesheetMaxBankAmount: number,
  issuer: shape(),
  intl: shape(),
  children: func,
  ratesheet: shape(),
};
