import './records-presenter.scss';

import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { codes } from '../../../api/holding';
import { DateWithTimeZone } from '../../../date';
import { percentual } from '../../../format-numbers';
import { Loadable, Row } from '../../common';

export const columns = {
  instrumentCode: { label: 'type' },
  issuer: { label: 'holdingIssuer', sort: 'issuerName' },
  rating: { label: 'holdingRating' },
  principal: { label: 'principal', sort: 'principal' },
  additionalFunds: { label: 'rfqsAdditionalFunds' },
  paid: { label: 'holdingPaid', sort: 'interestPaidCode' },
  coupon: { label: 'holdingCoupon', sort: 'coupon' },
  allocation: { label: 'holdingAllocation', sort: 'allocationCode' },
  settlementDate: { label: 'holdingSettlementDate', sort: 'settlementDate' },
  actions: { label: null },
};

export const presenter = (data, index, actions, options) => (
  <tr key={index}>
    <td className="td-1-space">{findLabelForInstrumentCode(data.instrumentCode)}</td>
    <td className="td-2-space">{data.issuer.name}</td>
    <td className="td-1-space">{String(data.rating).toUpperCase() === 'Z' ? 'Unrated' : data.rating}</td>
    <td className="td-1-space">
      <FormattedNumber {...percentual} value={data.principal} />
    </td>
    <td className="td-1-space">{renderAdditionalFunds(data.additionalFunds)}</td>
    <td>
      <FormattedMessage tagName="span" id={`interestDescription.${data.interestPaidCode}`} />
    </td>
    <td className="td-1-space">
      <FormattedNumber {...percentual} value={data.coupon} />
    </td>
    <td className="allocation-code-column td-1-space">{data.allocationCode && data.allocationCode.toUpperCase()}</td>
    {options.hasDefaultTPlusNDays && (
      <td className="td-1-space">{data.settlementDate ? <DateWithTimeZone value={data.settlementDate} /> : '-'}</td>
    )}
    <td className="actions">
      <Loadable skeleton isLoading={data.isConfirming} size="sm">
        {renderActionContent(actions, data)}
      </Loadable>
    </td>
  </tr>
);

const findLabelForInstrumentCode = (code) => {
  const filteredCode = codes.instrumentCodes.find((c) => c.code === code);
  if (filteredCode) {
    return filteredCode.label;
  }

  return code;
};

function renderAdditionalFunds(funds) {
  return funds ? <FormattedNumber {...percentual} value={funds} /> : '-';
}

function renderActionContent(actions, data) {
  const isClosed = data.rfqStatus && data.rfqStatus === 'closed';
  const neutral = isClosed || data.quotesReceived === 0;
  const classes = classNames('btn-table', { neutral });

  if (data.maturityAction === 'redeemed') {
    return null;
  }

  if (!['CP', 'ECD', 'TD'].includes(data.instrumentCode)) {
    return <span>-</span>;
  }

  if (data.rfqStatus || isClosed) {
    return (
      <Row contentEnd>
        {data.offer && data.offer.status !== 'rejected' && data.rfqStatus !== 'closed' && (
          <Button
            data-testid="view-offer-button"
            onClick={() => actions.viewOffer(data)}
            className={classNames(classes)}
          >
            <FormattedMessage tagName="span" id="viewOffer" />
          </Button>
        )}
        <Button
          onClick={() => performActionForData(actions, data)}
          className={classNames(classes, 'large', { secondary: neutral })}
        >
          <FormattedMessage tagName="span" {...formattedActionsParameters(data)} />
        </Button>
      </Row>
    );
  }

  return (
    <Row contentEnd>
      {data.offer && data.offer.status !== 'rejected' && (
        <Button data-testid="view-offer-button" onClick={() => actions.viewOffer(data)} className={classNames(classes)}>
          <FormattedMessage tagName="span" id="viewOffer" />
        </Button>
      )}

      <Button data-testid="redeem-button" onClick={() => redeemAction(actions, data)} className={classNames(classes)}>
        <FormattedMessage tagName="span" id="redeem" />
      </Button>

      <Button
        data-testid="main-action-button"
        onClick={() => performActionForData(actions, data)}
        className={classNames(classes)}
      >
        <FormattedMessage tagName="span" {...formattedActionsParameters(data)} />
      </Button>
    </Row>
  );
}

function redeemAction(actions, data) {
  return actions.redeem(data);
}

function performActionForData(actions, data) {
  return data.rfqStatus ? actions.viewReceivedQuotes(data) : actions.reinvest(data);
}

function formattedActionsParameters(data) {
  const status = data.rfqStatus;

  if (!status) {
    return { id: 'reinvestViaRfq' };
  }

  return status === 'closed'
    ? { id: 'rfqDealt' }
    : { id: 'rfqQuotesReceived', values: { quotes: data.quotesReceived } };
}
