import axios from 'axios';
import { buildHeaders } from '..';

const defaultMaxAutoQuoteAmount = 10 * 1000 * 1000;

export const updateAutoQuotingEnabled = async (autoQuotingEnabled) => {
  const headers = buildHeaders();

  const { data } = await axios({
    method: 'put',
    url: '/tenants/settings',
    headers,
    data: {
      // Need to invert the enabled state since the setting works with disabled state
      autoQuoteDisabled: autoQuotingEnabled ? false : true,
    },
  });

  return data;
};
