import classNames from 'classnames';
import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { FaEllipsisV, FaFile } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Money } from '../../../containers/money';
import { DateWithTimeZone } from '../../../date';
import { emptyCharacter, fullCouponFormat, percentual } from '../../../format-numbers';
import { getTradeRating } from '../../../get-trade-rating';
import { PulseBlob, Tooltip } from '../../common';
import { downloadCashBalance } from './download-cash-balance';
import { findLabelForInstrumentCode } from './find-label-for-instrument-code';

/* eslint-disable react/prop-types */
const buildTooltip = (trade) => {
  const tradeId = <div>Trade ID: {trade.id}</div>;
  const issuer = <div>Issuer: {`${trade.issuer.code} (${trade.issuerId})`}</div>;
  const isin = trade.instrumentDetails && trade.instrumentDetails.isin && (
    <div>ISIN: {trade.instrumentDetails.isin}</div>
  );
  const ref = trade.reference && <div>Reference: {trade.reference}</div>;
  const bankRef = trade.bankReference && <div>Bank Ref: {trade.bankReference}</div>;

  return (
    <div className="trade-tooltip">
      {tradeId}
      {issuer}
      {isin}
      {ref}
      {bankRef}
    </div>
  );
};

const buildEllipsisTextPresenter = (notes) =>
  notes ? (
    <Tooltip id={notes} tooltipComponent={<>{notes}</>}>
      <td>
        <span className="text-ellipsis">{notes ? notes : emptyCharacter}</span>
      </td>
    </Tooltip>
  ) : (
    <td>{emptyCharacter}</td>
  );

export const columns = (currencySymbol) => [
  {
    label: 'type',
    key: 'instrumentCode',
    show: true,
    presenter: ({ id, instrumentCode, ...item }) => (
      <Tooltip id={`${id}`} tooltipComponent={buildTooltip({ id, instrumentCode, ...item })}>
        <td>{findLabelForInstrumentCode(instrumentCode)}</td>
      </Tooltip>
    ),
  },
  {
    label: 'holdingIssuer',
    key: 'issuer',
    sort: 'issuerName',
    show: true,
    presenter: ({ id, issuer, ...item }) => (
      <Tooltip id={`${id}`} tooltipComponent={buildTooltip({ id, issuer, ...item })}>
        <td>{issuer.name}</td>
      </Tooltip>
    ),
  },
  {
    label: 'holdingRating',
    key: 'rating',
    sort: 'rating',
    show: true,
    presenter: (trade) => {
      const definedRating = getTradeRating(trade);

      return <td>{String(definedRating).toUpperCase() === 'Z' ? 'Unrated' : definedRating}</td>;
    },
  },
  {
    label: 'holdingAllocation',
    key: 'allocation',
    sort: 'allocationCode',
    show: true,
    presenter: ({ allocationCode }) => <td className="allocation-code-column">{allocationCode.toUpperCase()}</td>,
  },
  {
    label: 'customHoldingPrincipal',
    key: 'principal',
    sort: 'principal',
    values: { currencySymbol },
    show: true,
    presenter: ({ principal }) => (
      <td>
        <Money value={principal} />
      </td>
    ),
  },
  {
    label: 'holdingColumnCleanPrice',
    key: 'cleanPrice',
    sort: 'instrumentDetails.cleanPrice',
    show: false,
    presenter: ({ instrumentDetails }) => (
      <td>
        {instrumentDetails && instrumentDetails.cleanPrice ? (
          <FormattedNumber minimumFractionDigits={3} maximumFractionDigits={3} value={instrumentDetails.cleanPrice} />
        ) : (
          emptyCharacter
        )}
      </td>
    ),
  },
  {
    label: 'holdingColumnGrossPrice',
    key: 'grossPrice',
    sort: 'instrumentDetails.grossPrice',
    show: false,
    presenter: ({ instrumentDetails }) => (
      <td>
        {instrumentDetails && instrumentDetails.grossPrice ? (
          <FormattedNumber minimumFractionDigits={3} maximumFractionDigits={3} value={instrumentDetails.grossPrice} />
        ) : (
          emptyCharacter
        )}
      </td>
    ),
  },
  {
    label: 'holdingEffectiveCleanPriceColumn',
    key: 'effectiveCleanPrice',
    sort: 'instrumentDetails.effectiveCleanPrice',
    show: false,
    presenter: ({ instrumentDetails }) => (
      <td>
        {instrumentDetails && instrumentDetails.effectiveCleanPrice ? (
          <FormattedNumber
            minimumFractionDigits={3}
            maximumFractionDigits={3}
            value={instrumentDetails.effectiveCleanPrice}
          />
        ) : (
          emptyCharacter
        )}
      </td>
    ),
  },
  {
    label: 'holdingEffectiveGrossPriceColumn',
    key: 'effectiveGrossPrice',
    sort: 'instrumentDetails.effectiveGrossPrice',
    show: false,
    presenter: ({ instrumentDetails }) => (
      <td>
        {instrumentDetails && instrumentDetails.effectiveGrossPrice ? (
          <FormattedNumber
            minimumFractionDigits={3}
            maximumFractionDigits={3}
            value={instrumentDetails.effectiveGrossPrice}
          />
        ) : (
          emptyCharacter
        )}
      </td>
    ),
  },
  {
    label: 'holdingPurchaseDate',
    key: 'purchaseDate',
    sort: 'purchaseDate',
    show: true,
    presenter: ({ purchaseDate }) => (
      <td>
        <DateWithTimeZone value={purchaseDate} />
      </td>
    ),
  },
  {
    label: 'holdingMaturityDate',
    key: 'maturityDate',
    sort: 'maturityDate',
    show: true,
    presenter: ({ maturityDate }) => (
      <td>{maturityDate ? <DateWithTimeZone value={maturityDate} /> : emptyCharacter}</td>
    ),
  },
  {
    label: 'holdingDaysCountToMaturity',
    key: 'daysCountToMaturity',
    sort: 'daysCountToMaturity',
    show: false,
    presenter: ({ daysCountToMaturity }) => (
      <td>
        {daysCountToMaturity || daysCountToMaturity === 0 ? (
          <FormattedNumber value={daysCountToMaturity} />
        ) : (
          emptyCharacter
        )}
      </td>
    ),
  },
  {
    label: 'holdingNextCouponDate',
    key: 'nextCouponDate',
    sort: 'nextCouponDate',
    show: true,
    presenter: ({ nextCouponDate }) => (
      <td>{nextCouponDate ? <DateWithTimeZone value={nextCouponDate} /> : emptyCharacter}</td>
    ),
  },
  {
    label: 'faceValue',
    key: 'notionalPrincipal',
    sort: 'notionalPrincipal',
    values: { currency: currencySymbol },
    show: false,
    presenter: ({ notionalPrincipal }) => (
      <td>{notionalPrincipal ? <Money value={notionalPrincipal} /> : emptyCharacter}</td>
    ),
  },
  {
    label: 'holdingCoupon',
    key: 'coupon',
    sort: 'coupon',
    show: true,
    presenter: ({ coupon }) => (
      <td>{coupon ? <FormattedNumber {...fullCouponFormat} value={coupon} /> : emptyCharacter}</td>
    ),
  },
  {
    label: 'accruedInterest',
    key: 'accruedInterest',
    values: { currencySymbol },
    show: true,
    presenter: ({ interests: { current } }) => (
      <td>
        <Money value={current.accrued} hideZero />
      </td>
    ),
  },
  {
    label: 'holdingColumnMonthToDate',
    key: 'monthToDate',
    values: { currencySymbol },
    show: false,
    presenter: ({ interests: { current } }) => (
      <td>
        <Money value={current.monthToDate} hideZero />
      </td>
    ),
  },
  {
    label: 'portfolioPercentage',
    key: 'portfolioPercentage',
    sort: 'principal',
    show: true,
    presenter: ({ portfolioPercentage }) => (
      <td>{portfolioPercentage ? <FormattedNumber {...percentual} value={portfolioPercentage} /> : emptyCharacter}</td>
    ),
  },
  {
    label: 'holdingPaid',
    key: 'paid',
    sort: 'interestPaidCode',
    show: true,
    presenter: ({ interestPaidCode }) => (
      <td>
        <FormattedMessage tagName="span" id={`interestDescription.${interestPaidCode}`} />
      </td>
    ),
  },
  {
    label: 'isin',
    key: 'isin',
    sort: 'instrumentDetails.isin',
    show: false,
    presenter: ({ instrumentDetails }) => <td>{(instrumentDetails && instrumentDetails.isin) || emptyCharacter}</td>,
  },
  {
    label: 'unrealisedGains',
    key: 'instrumentDetails.unrealised',
    values: { currencySymbol },
    show: false,
    presenter: ({ instrumentDetails }) => (
      <td>
        {(instrumentDetails && instrumentDetails.unrealisedGains && (
          <Money value={instrumentDetails.unrealisedGains} />
        )) ||
          emptyCharacter}
      </td>
    ),
  },
  {
    label: 'holdingColumnYieldTradeMargin',
    key: 'yieldOrTradeMargin',
    sort: 'isinPrice.yieldOrTradeMargin',
    show: false,
    presenter: ({ isinPrice }) => <td>{(isinPrice && isinPrice.yieldOrTradeMargin) || emptyCharacter}</td>,
  },
  {
    label: 'instrumentQuantity',
    key: 'instrumentQuantity',
    sort: 'instrumentDetails.quantity',
    show: false,
    presenter: ({ instrumentDetails }) => (
      <td>{(instrumentDetails && instrumentDetails.quantity) || emptyCharacter}</td>
    ),
  },
  {
    label: 'holdingColumnFrnIssueMargin',
    key: 'frnIssueMargin',
    sort: 'instrumentDetails.frnIssueMargin',
    show: false,
    presenter: ({ instrumentDetails }) => (
      <td>{(instrumentDetails && instrumentDetails.frnIssueMargin) || emptyCharacter}</td>
    ),
  },
  {
    label: 'holdingColumnSource',
    key: 'source',
    sort: 'source',
    show: false,
    presenter: ({ source }) => <td>{source && getTradeSourceDescription(source)}</td>,
  },
  {
    label: 'holdingLastCouponDate',
    key: 'lastCouponDate',
    sort: 'lastCouponDate',
    show: false,
    presenter: ({ lastCouponDate }) => (
      <td>{lastCouponDate ? <DateWithTimeZone value={lastCouponDate} /> : emptyCharacter}</td>
    ),
  },
  {
    label: 'holdingTransactionDate',
    key: 'transactionDate',
    sort: 'transactionDate',
    show: false,
    presenter: ({ transactionDate }) => (
      <td>{transactionDate ? <DateWithTimeZone value={transactionDate} /> : emptyCharacter}</td>
    ),
  },
  {
    label: 'holdingColumnNote',
    key: 'note',
    sort: 'note',
    show: false,
    presenter: ({ note }) => buildEllipsisTextPresenter(note),
  },
  {
    label: 'holdingColumnReference',
    key: 'reference',
    sort: 'reference',
    show: false,
    presenter: ({ reference }) => buildEllipsisTextPresenter(reference),
  },
  {
    label: 'holdingColumnBankReference',
    key: 'bankReference',
    sort: 'bankReference',
    show: false,
    presenter: ({ bankReference }) => buildEllipsisTextPresenter(bankReference),
  },
  {
    show: true,
    key: 'pulse',
    width: 50,
    presenter: (item) =>
      shouldHighlightOutgoingRfq({ trade: item }) ? (
        <Tooltip id="thereIsAnOngoingRfq">
          <td className="pulse-blob-column">
            <PulseBlob />
          </td>
        </Tooltip>
      ) : (
        <td />
      ),
  },
  {
    show: true,
    key: 'actions',
    width: '2%',
    presenter: (item, actions, options) => (
      <td className="action-menu">
        {item.documentReferenceKey && (
          <FaFile
            className={classNames({ 'is-downloading': options?.isDownloadingTradeId === item.id })}
            onClick={() => actions.downloadDocument({ id: item.id, documentReferenceKey: item.documentReferenceKey })}
          />
        )}
        <DropdownButton
          id="actions"
          className="action-menu"
          pullRight
          noCaret
          bsStyle="link"
          title={
            <span>
              <FaEllipsisV />
            </span>
          }
        >
          {item.instrumentCode === 'CASH' && (
            <MenuItem onClick={() => downloadCashBalance(item.id)}>
              <FormattedMessage tagName="span" id="downloadBalanceHistory" />
            </MenuItem>
          )}
          <MenuItem onClick={() => actions.showNoteAndReferenceDialog(item)}>
            <FormattedMessage tagName="span" id="updateTradeNoteAndReferences" />
          </MenuItem>
          {buildSellBondRfqMenuItem({ trade: item, actions })}
        </DropdownButton>
      </td>
    ),
  },
];

const getTradeSourceDescription = (source) => (source.indexOf('rfq') >= 0 ? 'RFQ' : 'Manual');

const shouldHighlightOutgoingRfq = ({ trade }) => {
  if (!trade.sellRfq) {
    return false;
  }

  if (trade.sellRfq.rfqStatus === 'open') {
    return true;
  }

  const isWaitingConfirmation = trade.sellRfq.rfqStatus === 'closed' && !trade.sellRfq.confirmedAt;

  return isWaitingConfirmation;
};

const buildSellBondRfqMenuItem = ({ trade, actions }) => {
  if (!['BOND', 'FRN'].includes(trade.instrumentCode)) {
    return null;
  }

  const { sellRfq } = trade;
  const menuItemCreateSellBondRfq = (
    <MenuItem onClick={() => actions.showSellBondForm(trade)}>
      <FormattedMessage tagName="span" id="requestQuotesToSell" />
    </MenuItem>
  );

  if (!sellRfq) return menuItemCreateSellBondRfq;

  const isSellRfqCancelledOrExpired = ['cancelled', 'expired'].includes(sellRfq.rfqStatus);

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => actions.showCloseSellBondRfqDrawer({ sellBondRfqEntityId: sellRfq.sellBondRfqEntityId })}
      >
        {shouldHighlightOutgoingRfq({ trade }) && <PulseBlob />}
        <FormattedMessage tagName="span" id={isSellRfqCancelledOrExpired ? 'viewPreviousRFQ' : 'viewRFQ'} />
      </MenuItem>
      {isSellRfqCancelledOrExpired && menuItemCreateSellBondRfq}
    </React.Fragment>
  );
};
