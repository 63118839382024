import './collation-of-ratesheets.scss';

import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Tab } from 'react-bootstrap';
import { Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import useSWR, { mutate } from 'swr';

import { setRFQInitialValues } from '../../../../../../actions/holding/rfq/rfq';
import { getUser } from '../../../../../../actions/session-selector';
import { allTenors, tradeSources } from '../../../../../../api/holding/codes';
import { buildFilteredTradesUrl } from '../../../../../../api/holding/filtered-trades';
import { getTradesMaturingSummary, getTradesMaturingSummaryUrl } from '../../../../../../api/holding/maturing-summary';
import { acceptBespokeUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/investor/accept-bespoke-unsolicited-ratesheet';
import { acceptDefaultUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/investor/accept-default-unsolicited-ratesheet';
import {
  getInvestorsUnsolicitedRatesheetsUrl,
  getInvestorUnsolicitedRatesheets,
} from '../../../../../../api/unsolicited-ratesheets/investor/get-investor-actionable-unsolicited-ratesheets';
import { InputSearch, ResultsPresenter, roles, Tabs } from '../../../../../../components/common';
import { withNavigate } from '../../../../../../components/hoc/with-router-properties';
import { routes } from '../../../../../../routes';
import { getMoneySymbol } from '../../../../../money';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../../../../../toast/toast';
import { columns, presenter } from './collation-of-ratesheets-presenter';
import { CollationOfRatesheetsHeader } from './CollationOfRatesheetsHeader';
import { InvestorUltimateCurveRatesheet } from './InvestorUltimateCurveRatesheet';

const current = 'current';
const ultimateCurve = 'ultimate-curve';

const mapStateToProps = (state) => ({
  currency: state.session.user.currency,
  user: getUser(state),
});

export const CollationOfRatesheetsComponent = ({
  currency,
  navigate,
  setInitialValuesInRfq,
  user,
  intl,
  onCreateRfq,
  onReinvestRfq,
}) => {
  const tabs = [
    {
      key: current,
      href: current,
      component: <FormattedMessage tagName="span" id="unsolicitedRatesheet" />,
      requiredTo: roles.finance,
      user,
    },
  ];

  const { data, error } = useSWR(getInvestorsUnsolicitedRatesheetsUrl, getInvestorUnsolicitedRatesheets);
  const { data: maturingSummary, mutate: mutateMaturingSummary } = useSWR(
    getTradesMaturingSummaryUrl,
    getTradesMaturingSummary,
  );

  const [investorUnsolicitedRatesheets, setInvestorUnsolicitedRatesheets] = useState([]);
  const [filter, setFilter] = useState({});
  const [isAccepting, setIsAccepting] = useState(false);
  const [sortColumn, setSortColumn] = useState('updatedAt');
  const [sortDirectionDesc, setSortDirectionDesc] = useState(false);

  const applySort = useCallback(
    (ratesheets) => {
      if (!ratesheets || !ratesheets.length) return ratesheets;

      const sorted = ratesheets.sort((leftRatesheet, rightRatesheet) => {
        if (sortColumn === 'issuerName') {
          return leftRatesheet.issuer.name.localeCompare(rightRatesheet.issuer.name);
        }

        if (allTenors.includes(sortColumn)) {
          const rate1 = leftRatesheet.ratesheet.rates.find((rate) => rate.tenor === sortColumn)?.rate || 0;
          const rate2 = rightRatesheet.ratesheet.rates.find((rate) => rate.tenor === sortColumn)?.rate || 0;

          return rate1 > rate2 ? -1 : 1;
        }

        return leftRatesheet[sortColumn] < rightRatesheet[sortColumn] ? -1 : 1;
      });

      return sortDirectionDesc ? sorted.reverse() : sorted;
    },
    [sortColumn, sortDirectionDesc],
  );

  const onSortColumnOrder = ({ sort }) => {
    if (sort === sortColumn) {
      setSortDirectionDesc(!sortDirectionDesc);
    } else {
      setSortColumn(sort);
      setSortDirectionDesc(false);
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    let nextInvestorUnsolicitedRatesheets = Array.from(data);

    if (filter.issuerName) {
      nextInvestorUnsolicitedRatesheets = nextInvestorUnsolicitedRatesheets.filter(
        ({ issuer }) => !filter.issuerName || issuer?.name.match(new RegExp(filter.issuerName, 'i')),
      );
    }

    nextInvestorUnsolicitedRatesheets = applySort(nextInvestorUnsolicitedRatesheets);

    setInvestorUnsolicitedRatesheets(nextInvestorUnsolicitedRatesheets);
  }, [data, filter, sortColumn, sortDirectionDesc, applySort]);

  const filterByIssuerName = (event) => setFilter(() => ({ ...filter, issuerName: event.target.value }));

  const onAcceptDefaultRatesheetConfirm = async (values) => {
    try {
      setIsAccepting(true);

      await acceptDefaultUnsolicitedRatesheet(values);

      showToastMessage(intl.formatMessage({ id: 'tradeFromRateCreatedSuccessfully' }), MessageType.SUCCESS);
      updateHeaderSummary();
      mutateMaturingSummary();
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setIsAccepting(false);
    }
  };

  const onAcceptBespokeRatesheetConfirm = async (values) => {
    try {
      setIsAccepting(true);

      await acceptBespokeUnsolicitedRatesheet(values);

      showToastMessage(intl.formatMessage({ id: 'tradeFromRateCreatedSuccessfully' }), MessageType.SUCCESS);
      updateHeaderSummary();
      mutateMaturingSummary();
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setIsAccepting(false);
    }
  };

  const onCreateRfqClick = (initialValues) => {
    const params = {
      initialValues: {
        ...initialValues,
        sourceIssuerId: initialValues.issuerId,
        issuersIds: investorUnsolicitedRatesheets.map(({ issuer }) => issuer.id),
        source: investorUnsolicitedRatesheets.find(({ issuer }) => issuer.id === initialValues.issuerId)
          ?.unsolicitedRatesheetEntityId
          ? tradeSources.bespokeUnsolicitedRatesheet
          : tradeSources.defaultUnsolicitedRatesheet,
      },
    };

    if (onCreateRfq) {
      return onCreateRfq(params);
    }

    setInitialValuesInRfq(params);

    navigate(routes.holdings.newRfq, {
      state: { from: routes.unsolicitedRatesheets.investor.base },
    });
  };

  const onReinvestRfqClick = ({ id, ...initialValues }) => {
    const params = {
      id,
      initialValues: {
        ...initialValues,
        sourceIssuerId: initialValues.issuerId,
        issuersIds: investorUnsolicitedRatesheets.map(({ issuer }) => issuer.id),
        source: investorUnsolicitedRatesheets.find(({ issuer }) => issuer.id === initialValues.issuerId)
          ?.unsolicitedRatesheetEntityId
          ? tradeSources.bespokeUnsolicitedRatesheet
          : tradeSources.defaultUnsolicitedRatesheet,
      },
    };

    if (onReinvestRfq) {
      return onReinvestRfq(params);
    }

    setInitialValuesInRfq(params);

    navigate(routes.holdings.rfq.replace(':id', id), {
      state: { from: routes.unsolicitedRatesheets.investor.base },
    });
  };

  const renderMaturitiesFilter = () => (
    <Row className="unsolicited-ratesheet-filter" wrap>
      <Row justifyContentSpaceBetween>
        <div className="customer-filter filter-item">
          <p className="grey-color text-ellipsis">
            <FormattedMessage id="unsolicitedRatesheetBank" />
          </p>
          <InputSearch
            defaultValue={filter.issuerName}
            onChange={filterByIssuerName}
            onClear={() => setFilter(() => ({ ...filter, issuerName: undefined }))}
          />
        </div>
        <Button onClick={() => setFilter({})}>
          <FormattedMessage id="reset" />
        </Button>
      </Row>
    </Row>
  );

  const updateHeaderSummary = () =>
    [
      buildFilteredTradesUrl({ source: tradeSources.defaultUnsolicitedRatesheet }),
      buildFilteredTradesUrl({ source: tradeSources.bespokeUnsolicitedRatesheet }),
    ].forEach((urlKey) => {
      mutate(urlKey);
    });

  const currencySymbol = getMoneySymbol({ currency, short: true });
  const presenterBuilderMetadata = {
    isFetching: false,
    noRecords: !investorUnsolicitedRatesheets?.length,
    recordsPresenter: {
      actions: {
        onCreateRfqClick,
        onReinvestRfqClick,
        onAcceptDefaultRatesheetConfirm,
        onAcceptBespokeRatesheetConfirm,
      },
      data: investorUnsolicitedRatesheets,
      presenter,
      columns: columns({ currencySymbol }),
      options: {
        isAccepting,
        maturingSummary,
      },
      onSortColumnOrder,
      highlightedColumn: `${!sortDirectionDesc ? '-' : ''}${sortColumn}`,
    },
    filter: {
      component: renderMaturitiesFilter(),
    },
    hasError: !!error,
  };

  if (investorUnsolicitedRatesheets.length) {
    tabs.push({
      key: ultimateCurve,
      href: ultimateCurve,
      component: <FormattedMessage tagName="span" id="unsolicitedRatesheetUltimateCurve" />,
      requiredTo: roles.finance,
      user,
    });
  }

  return (
    <section
      aria-label="collation of ratesheets section"
      className={classNames('collation-of-ratesheets-container light-tabs secondary', { 'is-loading': !data })}
    >
      <CollationOfRatesheetsHeader />

      <Tab.Container defaultActiveKey={current}>
        <React.Fragment>
          <Row contentBetween alignItemsCenter className="tab-container-header">
            <Tabs items={tabs} />
          </Row>
          <Tab.Content className="actionable-unsolicited-ratesheets" animation>
            <Tab.Pane key={current} eventKey={current} mountOnEnter className="unsolicited-ratesheets-pane">
              <ResultsPresenter {...presenterBuilderMetadata} />
            </Tab.Pane>
            {investorUnsolicitedRatesheets.length ? (
              <Tab.Pane
                key={ultimateCurve}
                eventKey={ultimateCurve}
                mountOnEnter
                className="unsolicited-ratesheets-pane"
              >
                <InvestorUltimateCurveRatesheet
                  investorUnsolicitedRatesheets={investorUnsolicitedRatesheets}
                  maturingSummary={maturingSummary}
                  onAcceptDefaultRatesheetConfirm={onAcceptDefaultRatesheetConfirm}
                  onAcceptBespokeRatesheetConfirm={onAcceptBespokeRatesheetConfirm}
                  onCreateRfqClick={onCreateRfqClick}
                  onReinvestRfqClick={onReinvestRfqClick}
                />
              </Tab.Pane>
            ) : null}
          </Tab.Content>
        </React.Fragment>
      </Tab.Container>
    </section>
  );
};

CollationOfRatesheetsComponent.propTypes = {
  currency: PropTypes.string.isRequired,
  onCreateRfq: PropTypes.func,
  onReinvestRfq: PropTypes.func,
};

const mapDispatchToProps = {
  setInitialValuesInRfq: setRFQInitialValues,
};

export const CollationOfRatesheets = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withNavigate,
)(CollationOfRatesheetsComponent);
