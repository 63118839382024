import './holdings-page.scss';

import axios from 'axios';
import debounce from 'debounce';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as holdingActions from '../../actions/holding/holding';
import { getFilteredTrades } from '../../actions/holding/selectors';
import calculateWayWam from '../../actions/sandbox/weighted-average-calculator';
import * as sessionActions from '../../actions/session';
import rooms from '../../api/socket/rooms';
import { Column, Drawer, Select, ConfigurableTable } from '../../components/common';
import includeSocket from '../../components/hoc/include-socket';
import { withLocation, withSearchParams } from '../../components/hoc/with-router-properties';
import Filter from '../../components/holding/filter';
import { columns } from '../../components/holding/records-presenter';
import { downloadFile } from '../../download-file';
import { sellBondRfqRequestQuotesToSellMenuClicked, track } from '../../event-tracker';
import { getMoneySymbol } from '../money';
import { CloseSellBondRfqDrawer } from '../rfqs/buy-sell-bond-rfqs/sell-bonds-rfqs/seller/close-sell-bond-rfq/CloseSellBondRfqDrawer';
import { RequestQuotes } from '../rfqs/buy-sell-bond-rfqs/sell-bonds-rfqs/seller/create-sell-bond-rfq/RequestQuotes';
import {
  MessageType,
  showResponseErrorMessage,
  showResponseErrorMessageWithUpload,
  showToastMessage,
} from '../toast/toast';
import { TradeNoteDialog } from './TradeNoteDialog';
import { getSettings } from '../../actions/session-selector';

export class HoldingsPage extends Component {
  constructor(props) {
    super(props);
    this.onSearchChangeDebounced = debounce(this.onSearchChange, 800);
  }

  state = {
    showNoteAndRefDialog: false,
    selectedTrade: null,
    isFetchingCsv: false,
    sellableTrade: null,
    sellBondRfqEntityId: null,
  };

  componentDidMount() {
    const { location, holding, actions, searchParams } = this.props;

    if (location.search.length > 0) {
      actions.holding.updateFilterAsync(Object.fromEntries([...searchParams]));

      return;
    }

    holding.filter && Object.keys(holding.filter).length
      ? actions.session.updateLocationQuery(holding.filter)
      : this.fetchHoldingIfNeeded();
  }

  componentDidUpdate(previousProps) {
    if (!this.shouldUpdateLocationQuery(previousProps, this.props)) {
      return;
    }

    previousProps.actions.session.updateLocationQuery(this.props.holding.filter);
  }

  onDropdownFilterChange = (type, value) => this.props.actions.holding.updateFilterAsync({ [type]: value });

  onSearchChange = (searchText) => {
    if (this.isSearchTextTermInvalid(searchText)) return;

    this.props.actions.holding.updateFilterAsync({ searchText });
  };

  onSearchClear = () => this.props.actions.holding.updateFilterAsync({ searchText: '' });

  onSortColumnOrder = ({ sort }) => this.props.actions.holding.updateSortColumnAsync({ sort });

  onResetFilter = () => this.props.actions.holding.resetFilter();

  shouldUpdateLocationQuery = ({ holding: { filter } }, { holding: { filter: nextPropsFilter } }) =>
    nextPropsFilter && !isEqual(filter, nextPropsFilter);

  isSearchTextTermInvalid = (searchText) => searchText.length === 1;

  fetchHoldingIfNeeded(params) {
    this.props.actions.holding.fetchHoldingIfNeeded();
  }

  onDownloadReportClick = ({ value }) => {
    const {
      portfolio: {
        filter: { date: asOf },
      },
    } = this.props;

    this.setState({ isFetchingCsv: true }, async () => {
      await this.props.actions.holding.fetchHoldingCsv({ [value]: true, asOf });
      this.setState({ isFetchingCsv: false });
    });
  };

  hasErrorOnSearchText = (searchText) => !/^[A-zÀ-ÿ0-9&'() .-]*$/.test(searchText);

  calculateSummary = ({ asAt }) => {
    const {
      holding: { isFetching },
      trades,
    } = this.props;

    if (isFetching) return { isFetching };

    const { coupon, duration, principalTotal } = calculateWayWam(trades, asAt);

    return {
      isFetching,
      sum: principalTotal,
      count: trades.length,
      way: coupon,
      wam: duration,
    };
  };

  renderFilter({ holdingState }) {
    const { filter, availableFilters } = holdingState;

    const {
      holding: { isFetching, hasInitiated },
      searchParamsEntries,
    } = this.props;

    const downloadSelectOptions = [
      { value: 'all', label: <FormattedMessage id="download.csvAll" /> },
      { value: 'today', label: <FormattedMessage id="download.csv" /> },
      {
        value: 'grouped',
        label: <FormattedMessage id="download.csvGrouped" />,
      },
    ];

    const summary = this.calculateSummary({ asAt: searchParamsEntries.date });

    return (
      <div className="filter-row">
        <Filter
          shouldShowLoadingOnType={(value) => !this.isSearchTextTermInvalid(value)}
          validateOnType={(value) => this.hasErrorOnSearchText(value)}
          onSearchChange={this.onSearchChangeDebounced}
          onSearchClear={this.onSearchClear}
          onDropdownFilterChange={this.onDropdownFilterChange}
          summary={summary}
          filter={filter}
          availableFilters={availableFilters}
          onResetFilter={this.onResetFilter}
        />
        <Column className="download-trades">
          {hasInitiated && !isFetching && (
            <Select
              isLoading={this.state.isFetchingCsv}
              disabled={this.state.isFetchingCsv}
              isClearable={false}
              placeholder={<FormattedMessage id="download.prompt" />}
              options={downloadSelectOptions}
              onChange={this.onDownloadReportClick}
            />
          )}
        </Column>
      </div>
    );
  }

  showSellBondForm = (trade) => {
    track(sellBondRfqRequestQuotesToSellMenuClicked);

    this.setState({ sellableTrade: trade });
  };

  showNoteAndReferenceDialog = (trade) => this.setState({ showNoteAndRefDialog: true, selectedTrade: trade });

  downloadDocument = async ({ id, documentReferenceKey }) => {
    this.setState({ isDownloadingTradeId: id });

    try {
      const { data, headers } = await axios({
        headers: {
          Accept: 'application/octet-stream',
        },
        method: 'get',
        url: `/trades/${id}/documents/${documentReferenceKey}`,
      });

      const [, filename] = headers['content-disposition'].split('filename=');

      downloadFile({ filename, data });
    } catch (error) {
      showResponseErrorMessage({ intl: this.props.intl, error });
    } finally {
      this.setState({ isDownloadingTradeId: undefined });
    }
  };

  renderRecords(holdingState, trades, currency) {
    const { isFetching, filter, hasInitiated } = holdingState;
    const moneySymbol = getMoneySymbol({ currency, short: true });

    const payload = {
      isFetching: isFetching || !hasInitiated,
      noRecords: !trades.length,
      tableSettingsId: 'holdings',
      recordsPresenter: {
        data: trades,
        columns: columns(moneySymbol),
        actions: {
          onDoubleClick: this.showNoteAndReferenceDialog,
          showNoteAndReferenceDialog: this.showNoteAndReferenceDialog,
          showSellBondForm: this.showSellBondForm,
          downloadDocument: this.downloadDocument,
          showCloseSellBondRfqDrawer: ({ sellBondRfqEntityId }) => this.setState({ sellBondRfqEntityId }),
        },
        onSortColumnOrder: this.onSortColumnOrder,
        highlightedColumn: (filter && filter.sort) || holdingActions.DEFAULT_SORT_COLUMN,
        options: { isDownloadingTradeId: this.state.isDownloadingTradeId },
      },
      filter: {
        component: this.renderFilter({ holdingState }),
      },
    };

    return <ConfigurableTable {...payload} />;
  }

  onConfirmDeletion = async ({ tradeId }) => {
    try {
      await axios.put(`/trades/${tradeId}`, {
        deleteDocument: true,
      });
    } catch (e) {
      showResponseErrorMessage({ intl: this.props.intl, error: e });
    }
  };

  updateNoteAndReference = async ({ tradeId, note, reference, bankReference, safeCustodyCode, ...rest }) => {
    try {
      const { data: updatedTrade } = await axios.put(`/trades/${tradeId}`, {
        note,
        reference,
        bankReference,
        safeCustodyCode,
        ...rest,
      });

      this.setState({ showNoteAndRefDialog: false, selectedTrade: null });
      this.props.actions.holding.updateTrade({
        tradeId,
        note,
        reference,
        bankReference,
        safeCustodyCode,
        documentReferenceKey: updatedTrade.documentReferenceKey,
      });
      showToastMessage('Note and references saved', MessageType.SUCCESS);
    } catch (error) {
      showResponseErrorMessageWithUpload({ intl: this.props.intl, error, maxUploadSize: '10mb' });
    }
  };

  onRequestQuotesSuccess = () => {
    this.props.actions.holding.fetchHolding();
    this.setState({ sellableTrade: null });
  };

  render() {
    const { holding, session, trades, tenantSettings } = this.props;
    const { showNoteAndRefDialog, selectedTrade } = this.state;

    return (
      <div className="holding-list">
        {this.renderRecords(holding, trades, session.user.currency)}
        {selectedTrade && (
          <TradeNoteDialog
            show={showNoteAndRefDialog}
            trade={selectedTrade}
            onConfirm={({ tradeId, note, reference, bankReference, safeCustodyCode, document }) =>
              this.updateNoteAndReference({
                tradeId,
                note,
                reference,
                bankReference,
                safeCustodyCode,
                document,
              })
            }
            onConfirmDeletion={this.onConfirmDeletion}
            onCancel={() =>
              this.setState({
                showNoteAndRefDialog: false,
                selectedTrade: null,
              })
            }
            tenantSettings={tenantSettings}
          />
        )}
        <Drawer
          titleId="requestQuotesToSell"
          className="light request-quotes-to-sell-drawer"
          open={!!this.state.sellableTrade}
          shouldCloseOnClickOutside={false}
          onClose={() => this.setState({ sellableTrade: null })}
          width="40vw"
        >
          {this.state.sellableTrade && (
            <RequestQuotes
              trade={this.state.sellableTrade}
              onSuccess={this.onRequestQuotesSuccess}
              currency={session.user.currency}
              emit={this.props.emit}
            />
          )}
        </Drawer>
        <CloseSellBondRfqDrawer
          rfqUUID={this.state.sellBondRfqEntityId}
          onChangeRfqSuccess={this.props.actions.holding.fetchHolding}
          onCloseDrawer={() => this.setState({ sellBondRfqEntityId: null })}
          currency={session.user.currency}
        />
      </div>
    );
  }
}

HoldingsPage.propTypes = {
  actions: PropTypes.shape().isRequired,
  location: PropTypes.shape(),
  portfolio: PropTypes.shape(),
  holding: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  trades: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentTenantDomain: PropTypes.string.isRequired,
  emit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  holding: state.holding,
  trades: getFilteredTrades(state.holding),
  portfolio: state.portfolio,
  session: state.session,
  currentTenantDomain: state.tenant,
  tenantSettings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    holding: bindActionCreators(holdingActions, dispatch),
    session: bindActionCreators(sessionActions, dispatch),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  includeSocket({ rooms: [rooms.rfq] }),
  withLocation,
  withSearchParams,
  injectIntl,
)(HoldingsPage);
