import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Tooltip from '../../../../../../components/common/tooltip';
import { SimpleTenorItem } from '../../../../../Tenors/SimpleTenorItem';

export const InvestorUltimateCurveTenorItem = forwardRef(({ tenor, rate, issuers }, ref) => {
  const footer =
    issuers?.length === 1 ? (
      <p className="footer text-ellipsis">{issuers[0].name}</p>
    ) : issuers?.length > 1 ? (
      <p className="footer text-ellipsis">
        <FormattedMessage id="multipleBanks" />
      </p>
    ) : null;

  return (
    <SimpleTenorItem tenor={tenor} rate={rate} ref={ref}>
      <Tooltip id="investor-ultimate-curve-tenor-item-tooltip" tooltipComponent={footer} placement="bottom">
        <React.Fragment>{rate !== null && footer}</React.Fragment>
      </Tooltip>
    </SimpleTenorItem>
  );
});

InvestorUltimateCurveTenorItem.propTypes = {
  tenor: PropTypes.string.isRequired,
  rate: PropTypes.number,
  children: PropTypes.node,
};
