import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

import { isPercentageAllowed } from '../../format-numbers';

const changeRate = ({ setRate, tenor, rate, onChange }) => {
  if (rate > 100 || rate < -100) {
    return;
  }

  setRate(rate);
  onChange({ tenor, rate });
};

export const TenorPercentageItem = ({ disabled, onChange, changedRate, handleFocus, setRate, tenor, rate }) => {
  useEffect(() => {
    if (!disabled) {
      return;
    }

    rate !== null && onChange({ tenor, rate: null });
  }, [disabled, rate, onChange, tenor]);

  if (disabled) {
    return <span className="empty-percentage">-</span>;
  }

  return (
    <div className="percentage">
      <NumericFormat
        data-testid="percentage-input"
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale={2}
        value={changedRate}
        onFocus={handleFocus}
        allowedDecimalSeparators={['.', ',']}
        isAllowed={isPercentageAllowed}
        onValueChange={(values) => changeRate({ setRate, tenor, rate: +values.value, onChange })}
      />
      <span>%</span>
    </div>
  );
};

TenorPercentageItem.propTypes = {
  changedRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleFocus: PropTypes.func,
  onChange: PropTypes.func,
  rate: PropTypes.number,
  setRate: PropTypes.func,
  tenor: PropTypes.string.isRequired,
};
