import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import useSWR from 'swr';

import { hasSeparationOfDuties } from '../../../../../../actions/session-selector';
import { initialGeneralAllocationCode } from '../../../../../../api/holding/codes';
import { getTradesSummary, getTradesSummaryUrl } from '../../../../../../api/holding/summary';
import { DatePicker, FormikSelectField, ValidatedFormikField } from '../../../../../../components/common';
import { buildDatepickerOptionsWithLimitedDays } from '../../../../../../components/common/datepicker-options';
import { formatToShortDate, getBusinessDate, getDateInTimeFrameFrom, isSameDay, today } from '../../../../../../date';
import { splitTenor } from '../../../../../Tenors/TenorItem';
import { CollationOfRatesheetsReinvestmentForm } from './CollationOfRatesheetsReinvestmentForm';
import { CreateTradeFromRatePopoverForm } from './CreateTradeFromRateForm';

export const initialFormValues = {
  principal: '',
  issuerId: '',
};

const steps = {
  chooseYourDestiny: 'chooseYourDestiny',
  newFund: 'newFund',
  reinvestment: 'reinvestment',
};

const initialGeneralAllocationCodes = [initialGeneralAllocationCode];

export const InvestorUltimateCurveCreateTradePopoverComponent = ({
  targetRef,
  show,
  tenor,
  rate,
  onHide,
  onCreateRfqClick,
  onReinvestRfqClick,
  isAccepting,
  onSubmit,
  maturingSummary,
  hasSeparationOfDuties,
  investorUnsolicitedRatesheetsWithSameRates,
  selectedInvestorUnsolicitedRatesheet,
  setSelectedInvestorUnsolicitedRatesheet,
  ...props
}) => {
  const [step, setStep] = useState(
    maturingSummary?.maturingTodayNotDealtWith ? steps.chooseYourDestiny : steps.newFund,
  );

  const { data: summary } = useSWR(getTradesSummaryUrl, getTradesSummary);
  const popoverOverlayContainer = useRef();

  const { time, unit } = splitTenor(tenor);

  const [allocationCodes, setAllocationCodes] = useState(initialGeneralAllocationCodes);

  const renderIssuerSelect = ({ touched, errors }) => {
    if (!investorUnsolicitedRatesheetsWithSameRates || investorUnsolicitedRatesheetsWithSameRates.length <= 1) {
      return null;
    }

    return (
      <ValidatedFormikField
        name="bankIssuerId"
        labelId="issuer"
        component={FormikSelectField}
        touched={touched}
        errors={errors}
        options={investorUnsolicitedRatesheetsWithSameRates.map(({ issuer }) => ({
          value: issuer.id,
          label: `${issuer.name} (${issuer.shortRating})`,
        }))}
        onChange={(value) => {
          const issuer = investorUnsolicitedRatesheetsWithSameRates.find(({ issuer }) => issuer.id === value);

          setSelectedInvestorUnsolicitedRatesheet(issuer);
        }}
      />
    );
  };

  useEffect(() => {
    if (!summary?.allocationCodes?.length) {
      return;
    }

    setAllocationCodes(
      summary?.allocationCodes.map((allocation) => ({
        value: allocation.code,
        label: allocation.code,
      })),
    );
  }, [summary]);

  useEffect(() => {
    setStep(maturingSummary?.maturingTodayNotDealtWith ? steps.chooseYourDestiny : steps.newFund);
  }, [maturingSummary]);

  const tenorMaturityDate = getBusinessDate({
    date: getDateInTimeFrameFrom(today(), time, unit),
  }).toDate();

  const [customMaturityDate, setCustomMaturityDate] = useState(null);

  const isDifferentThanMaturityDateFromTenor = customMaturityDate && !isSameDay(customMaturityDate, tenorMaturityDate);

  const onCreateTradeSubmit = async (values) => {
    await onSubmit(
      Object.assign(
        values,
        isDifferentThanMaturityDateFromTenor && { customMaturityDate: formatToShortDate(customMaturityDate) },
      ),
    );

    onHide();
  };

  return (
    <React.Fragment>
      <Overlay
        trigger="click"
        placement="bottom"
        rootClose
        show={show}
        ref={popoverOverlayContainer}
        onHide={onHide}
        target={targetRef?.current}
        {...props}
      >
        <Popover
          className={classNames('create-trade-from-rate-popover light', { 'is-accepting': isAccepting })}
          id={tenor}
          title={
            <Column alignItemsStart>
              <FormattedMessage tagName="h4" id={`offerTenor${unit}`} values={{ count: time }} />
              {selectedInvestorUnsolicitedRatesheet ? (
                <p>Bank: {selectedInvestorUnsolicitedRatesheet.issuer.name}</p>
              ) : (
                <FormattedMessage tagName="p" id="noIssuerSelected" />
              )}
            </Column>
          }
        >
          {step === steps.chooseYourDestiny && (
            <Column className="create-trade-from-rate-popover-reinvestment" alignItemsCenter justifyContentCenter>
              <Row>
                <Column alignItemsCenter className="create-trade-from-rate-popover-reinvestment-box">
                  <h2>{maturingSummary.maturingTodayCount}</h2>
                  <FormattedMessage tagName="span" id="maturingToday" />
                </Column>
                <Column alignItemsCenter className="create-trade-from-rate-popover-reinvestment-box">
                  <h2>{maturingSummary.maturingTodayNotDealtWith}</h2>
                  <FormattedMessage tagName="span" id="maturitiesNotDealt" />
                </Column>
              </Row>
              <FormattedMessage
                id="unsolicitedRatesheetHasMaturitiesNotDealtWith"
                tagName="p"
                values={{ maturingTodayNotDealtWith: maturingSummary.maturingTodayNotDealtWith }}
              />
              <Row className="actions" justifyContentEnd>
                <Button className="btn-solid-primary" onClick={() => setStep(steps.reinvestment)}>
                  <FormattedMessage id="unsolicitedRatesheetReinvest" />
                </Button>
                <Button className="btn-solid-primary" onClick={() => setStep(steps.newFund)}>
                  <FormattedMessage id="unsolicitedRatesheetNewFunds" />
                </Button>
              </Row>
            </Column>
          )}
          {step !== steps.chooseYourDestiny && (
            <Column className="create-trade-from-rate-popover-form-header">
              <Row alignItemsStart>
                <FormattedMessage tagName="h3" id="rate" values={{ rate: rate.toFixed(2), b: (msg) => <b>{msg}</b> }} />
                <DatePicker
                  ariaLabelledBy="maturity date"
                  disableWeekends
                  disableHolidays
                  labelId="maturity"
                  onChange={(selectedDate) => setCustomMaturityDate(selectedDate)}
                  value={customMaturityDate || tenorMaturityDate}
                  wrapperClassName={classNames({ 'is-custom': isDifferentThanMaturityDateFromTenor })}
                  {...buildDatepickerOptionsWithLimitedDays({
                    date: tenorMaturityDate,
                    rangeLimit: selectedInvestorUnsolicitedRatesheet?.unsolicitedRatesheetCustomMaturityDateRangeLimit,
                  })}
                />
              </Row>
              {hasSeparationOfDuties && (
                <Row>
                  <FormattedMessage tagName="h3" id="unsolicitedRatesheetSeparationOfDuties" />
                </Row>
              )}
            </Column>
          )}
          {step === steps.newFund && (
            <CreateTradeFromRatePopoverForm
              allocationCodes={allocationCodes}
              tenor={tenor}
              rate={rate}
              issuer={selectedInvestorUnsolicitedRatesheet?.issuer}
              ratesheet={selectedInvestorUnsolicitedRatesheet?.ratesheet}
              onCancelClick={onHide}
              onCreateTradeSubmit={onCreateTradeSubmit}
              popoverContainer={popoverOverlayContainer}
              onCreateRfqClick={onCreateRfqClick}
              onAddAllocationCode={setAllocationCodes}
              unsolicitedRatesheetMaxBankAmount={
                selectedInvestorUnsolicitedRatesheet?.unsolicitedRatesheetMaxBankAmount
              }
              hasSeparationOfDuties={hasSeparationOfDuties}
            >
              {renderIssuerSelect}
            </CreateTradeFromRatePopoverForm>
          )}
          {step === steps.reinvestment && (
            <CollationOfRatesheetsReinvestmentForm
              allocationCodes={allocationCodes}
              tenor={tenor}
              rate={rate}
              issuer={selectedInvestorUnsolicitedRatesheet?.issuer}
              ratesheet={selectedInvestorUnsolicitedRatesheet?.ratesheet}
              onCancelClick={onHide}
              onCreateTradeSubmit={onCreateTradeSubmit}
              popoverContainer={popoverOverlayContainer}
              onReinvestRfqClick={onReinvestRfqClick}
              onAddAllocationCode={setAllocationCodes}
              unsolicitedRatesheetMaxBankAmount={
                selectedInvestorUnsolicitedRatesheet?.unsolicitedRatesheetMaxBankAmount
              }
              hasSeparationOfDuties={hasSeparationOfDuties}
            >
              {renderIssuerSelect}
            </CollationOfRatesheetsReinvestmentForm>
          )}
        </Popover>
      </Overlay>
    </React.Fragment>
  );
};

InvestorUltimateCurveCreateTradePopoverComponent.defaultProps = {
  isAccepting: false,
  investorUnsolicitedRatesheetsWithSameRates: [],
};

InvestorUltimateCurveCreateTradePopoverComponent.propTypes = {
  onHide: func,
  onCreateRfqClick: func,
  onReinvestRfqClick: func,
  onSubmit: func,
  targetRef: shape(),
  isAccepting: bool,
  maturingSummary: shape(),
  placement: string,
  investorUnsolicitedRatesheetsWithBestQuote: arrayOf(
    shape({
      unsolicitedRatesheetMaxBankAmount: number,
      issuer: arrayOf(
        shape({
          id: number,
          name: string,
          shortRating: string,
        }),
      ),
    }),
  ),
};

const mapStateToProps = (state) => ({
  hasSeparationOfDuties: hasSeparationOfDuties(state),
});

export const InvestorUltimateCurveCreateTradePopover = connect(mapStateToProps)(
  InvestorUltimateCurveCreateTradePopoverComponent,
);
