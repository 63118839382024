import React, { useCallback } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Column } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';

import { ImperiumIntlProvider } from '../../../../../intl-provider';

export const InvestorUltimateCurvePointsLayer = ({ points, onPointClick }) => {
  const handleClick = useCallback(
    (point) => (event) => {
      if (onPointClick) {
        const pointElement = event.target;

        onPointClick({
          tenor: point.data.x,
          rate: point.data.y,
          ref: {
            current: pointElement,
          },
        });
      }
    },
    [onPointClick],
  );

  const pointTooltip = ({ data }) => {
    const { issuers, x: tenor, y: rate } = data;

    const issuerInformation =
      issuers?.length === 1 ? issuers[0].name : issuers?.length > 1 ? <FormattedMessage id="multipleBanks" /> : null;

    return (
      <Popover id="information-popover" bsClass="ultimate-curve-popover light popover">
        <ImperiumIntlProvider>
          <div className="popover-content">
            <dl>
              <Column>
                <dt>
                  <FormattedMessage id="tenor" />
                </dt>
                <dd>{tenor}</dd>
              </Column>
              <Column>
                <dt>
                  <FormattedMessage id="unsolicitedRatesheetRate" />
                </dt>
                <dd>{rate}%</dd>
              </Column>
              {issuerInformation && (
                <Column>
                  <dt>
                    <FormattedMessage id="unsolicitedRatesheetBestQuote" />
                  </dt>
                  <dd>{issuerInformation}</dd>
                </Column>
              )}
            </dl>
          </div>
        </ImperiumIntlProvider>
      </Popover>
    );
  };

  return points.map((point) => (
    <OverlayTrigger key={`${point.id}-${point.index}`} placement="top" overlay={pointTooltip(point)} trigger="hover">
      <circle
        cx={point.x}
        cy={point.y}
        r={5}
        stroke="var(--primary-color)"
        strokeWidth={2}
        onClick={handleClick(point)}
        style={{ cursor: 'pointer' }}
      />
    </OverlayTrigger>
  ));
};
