import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { splitTenor } from './TenorItem';

export const SimpleTenorItem = forwardRef(({ tenor, rate, children }, ref) => {
  const { unit, time } = splitTenor(tenor);

  return (
    <React.Fragment>
      <FormattedMessage id={`offerTenor${unit}`} values={{ count: time }} />
      {rate !== null ? (
        <div ref={ref} className="percentage">
          <span>
            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={4} value={rate} />
          </span>
          <span>%</span>
        </div>
      ) : (
        <span className="empty-percentage">-</span>
      )}
      {children}
    </React.Fragment>
  );
});

SimpleTenorItem.propTypes = {
  tenor: PropTypes.string.isRequired,
  rate: PropTypes.number,
  children: PropTypes.node,
};
