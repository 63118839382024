import './collation-of-ratesheets.scss';

import React from 'react';

import { Row } from 'react-display-flex';

import { MaturingTodaySummaryItem } from '../../../../../../components/common/MaturingTodaySummaryItem/MaturingTodaySummaryItem';

export const CollationOfRatesheetsHeader = () => {
  return (
    <Row className="collation-of-ratesheets-header">
      <MaturingTodaySummaryItem />
    </Row>
  );
};
