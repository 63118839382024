import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-display-flex';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DateWithTimeZone, SummaryHighlight } from '../../components/common';
import { emptyCharacter, fullCouponFormat } from '../../format-numbers';
import { connect } from 'react-redux';
import { getSettings } from '../../actions/session-selector';

const BondAndFrnHeaderComponent = ({
  priceDate,
  yieldOrTradeMargin,
  safeCustodyCode,
  instrumentCode,
  tenantSettings,
}) => {
  const safeCustodyId = getCustodyCodeId({ safeCustodyCode, tenantSettings, instrumentCode });

  return (
    <Row className="dialog-header">
      <SummaryHighlight
        top={<FormattedMessage id="valuationDate" />}
        middle={priceDate ? <DateWithTimeZone value={priceDate} /> : emptyCharacter}
      />
      <SummaryHighlight
        top={<FormattedMessage id="yieldOrTradeMargin" />}
        middle={
          yieldOrTradeMargin ? <FormattedNumber {...fullCouponFormat} value={yieldOrTradeMargin} /> : emptyCharacter
        }
      />
      <SummaryHighlight
        top={<FormattedMessage id="safeCustodyId" />}
        middle={safeCustodyId ? safeCustodyId : emptyCharacter}
      />
    </Row>
  );
};

BondAndFrnHeaderComponent.propTypes = {
  priceDate: PropTypes.string,
  yieldOrTradeMargin: PropTypes.number,
  tenantSettings: PropTypes.shape({
    safeCustody: PropTypes.shape({
      austraclear: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    }),
  }),
};

const mapStateToProps = (state) => ({
  tenantSettings: getSettings(state),
});

export const BondAndFrnHeader = connect(mapStateToProps)(BondAndFrnHeaderComponent);

const getCustodyCodeId = ({ safeCustodyCode, instrumentCode, tenantSettings }) => {
  if (!safeCustodyCode || !instrumentCode || !tenantSettings?.safeCustody) return undefined;

  const safeCustodyCodeKey = String(safeCustodyCode).toLocaleLowerCase();
  const instrumentCodeKey = String(instrumentCode).toLocaleUpperCase();

  return tenantSettings.safeCustody[safeCustodyCodeKey] &&
    tenantSettings.safeCustody[safeCustodyCodeKey][instrumentCodeKey]
    ? tenantSettings.safeCustody[safeCustodyCodeKey][instrumentCodeKey]
    : undefined;
};
