import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Column, Protect, PulseBlob, roles, SummaryItem } from '../../../components/common';
import { Money } from '../../money';

const isRfqOpened = (status, confirmed) => status === 'open' || (status === 'closed' && confirmed === false);

const TotalizerComponent = ({ quotes, changeView, session: { user }, ...props }) => {
  const rfqs = quotes.rfqs || [];
  const openedRfqs = rfqs.filter(({ rfqStatus, rfqConfirmed }) => isRfqOpened(rfqStatus, rfqConfirmed));
  const rfqsTotal = openedRfqs.reduce((accumulated, rfq) => accumulated + rfq.principal, 0);
  const quotesReceivedCount = openedRfqs.reduce((acc, rfq) => acc + rfq.quotesReceived, 0);

  const hasOutgoingRfqs = openedRfqs.length > 0;

  const renderTotalQuotesReceived = () => {
    const quotesReceivedSpan = (
      <FormattedMessage
        tagName="span"
        id="portfolio.filter-quotes-received-number"
        values={{ length: quotesReceivedCount }}
      />
    );

    return quotesReceivedCount > 0 ? (
      <React.Fragment>
        <span className="highlight">{quotesReceivedSpan}</span>
        <PulseBlob />
      </React.Fragment>
    ) : (
      quotesReceivedSpan
    );
  };

  const summaryContent = (
    <SummaryItem
      top={<FormattedMessage id="outgoingRfqs" />}
      middle={<Money value={rfqsTotal} short fraction={rfqsTotal > 0 ? 2 : 0} />}
      bottom={hasOutgoingRfqs ? renderTotalQuotesReceived() : <FormattedMessage tagName="span" id="noOutgoingRfqs" />}
      highlight={quotesReceivedCount > 0}
      isLoading={quotes.isFetching}
      {...props}
    />
  );

  return (
    <Column className="summary-item">
      {hasOutgoingRfqs && quotesReceivedCount > 0 ? (
        <Protect user={user} requiredTo={roles.finance}>
          <button type="button" onClick={() => changeView()} className="action-button" data-testid="action-button">
            {summaryContent}
          </button>
        </Protect>
      ) : (
        summaryContent
      )}
    </Column>
  );
};

TotalizerComponent.propTypes = {
  session: PropTypes.shape().isRequired,
  quotes: PropTypes.shape().isRequired,
  changeView: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  quotes: state.quotes,
});

export const OutgoingRfqsTotalizer = connect(mapStateToProps)(TotalizerComponent);
