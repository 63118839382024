import './unsolicited-ratesheet-settings.scss';

import React, { useState } from 'react';

import classNames from 'classnames';
import { debounce } from 'debounce';
import propTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';

import { refreshSession as refreshSessionAction } from '../../../../../../actions/session';
import { getSettings, getUser, hasLicences } from '../../../../../../actions/session-selector';
import { licences } from '../../../../../../api/licences';
import { updateTenantSettings } from '../../../../../../api/tenant/update-settings';
import CurrencyInput from '../../../../../../components/common/currency-input';
import Tooltip from '../../../../../../components/common/tooltip';
import { number } from '../../../../../../format-numbers';
import { getMoneySymbol } from '../../../../../money';
import { MessageType, showToastMessage } from '../../../../../toast/toast';
import { ToggleAutoQuoting } from '../../../../../update-ratesheet/toggle-auto-quoting';

const debounceMaxInputChange = 1000;

const UnsolicitedRatesheetSettingsComponent = injectIntl(
  ({
    hasUnsolicitedRatesheetLicence,
    isUnsolicitedRatesheetDisabled,
    unsolicitedRatesheetMaxBankAmount,
    autoQuoteMaxBankAmount,
    isAutoQuoteFeaturesEnabledInUnsolicitedRatesheet,
    isAutoQuoteDisabled,
    refreshSession,
    user,
    intl,
  }) => {
    const [isUnsolicitedRatesheetChecked, setUnsolicitedRatesheetAvailability] = useState(
      !isUnsolicitedRatesheetDisabled,
    );

    const [isUnsolicitedRatesheetAvailabilityLoading, setIsUnsolicitedRatesheetAvailabilityLoading] = useState(false);

    if (!hasUnsolicitedRatesheetLicence) return null;

    const onDigitalRatesheetAvailabilityChange = async ({ target }) => {
      const { checked: isDigitalRatesheetOn } = target;
      try {
        const unsolicitedRatesheetDisabled = !isDigitalRatesheetOn;

        setIsUnsolicitedRatesheetAvailabilityLoading(true);

        await updateTenantSettings({ unsolicitedRatesheetDisabled });

        setIsUnsolicitedRatesheetAvailabilityLoading(false);
        setUnsolicitedRatesheetAvailability(isDigitalRatesheetOn);

        await refreshSession();
      } catch (err) {
        showToastMessage('There was an error updating the setting', MessageType.ERROR);
      }
    };

    const onMaxAmountChange = async ({ floatValue: nextUnsolicitedRatesheetMaxBankAmount }) => {
      try {
        await updateTenantSettings({
          unsolicitedRatesheetMaxBankAmount:
            nextUnsolicitedRatesheetMaxBankAmount === undefined ? null : nextUnsolicitedRatesheetMaxBankAmount,
        });

        await refreshSession();
      } catch (err) {
        showToastMessage('There was an error updating the setting', MessageType.ERROR);
      }
    };

    const onAutoQuoteMaxAmountChange = async ({ floatValue: nextAutoQuoteMaxBankAmount }) => {
      try {
        await updateTenantSettings({
          autoQuoteMaxBankAmount: nextAutoQuoteMaxBankAmount === undefined ? null : nextAutoQuoteMaxBankAmount,
        });

        await refreshSession();
      } catch (err) {
        showToastMessage('There was an error updating the setting', MessageType.ERROR);
      }
    };

    const currencySymbol = getMoneySymbol({ currency: user.currency, short: true });

    return (
      <section aria-label="tenant settings" className="bank-unsolicited-ratesheet-settings">
        <div className="settings-column">
          <div
            className={classNames('toggle-bank-unsolicited-ratesheet', {
              'is-loading': isUnsolicitedRatesheetAvailabilityLoading,
            })}
          >
            <FormattedMessage
              tagName="span"
              id={isUnsolicitedRatesheetChecked ? 'unsolicitedRatesheetOn' : 'unsolicitedRatesheetOff'}
            />
            <Toggle
              disabled={isUnsolicitedRatesheetAvailabilityLoading}
              checked={isUnsolicitedRatesheetChecked}
              icons={false}
              onChange={onDigitalRatesheetAvailabilityChange}
            />
          </div>
          <div className="bank-unsolicited-ratesheet-settings-max-amount">
            <label htmlFor="unsolicited-ratesheet-max-bank-amount">
              <FormattedMessage id="unsolicitedRatesheetMaxBankAmountLabel" />
            </label>
            <Tooltip id="unsolicitedRatesheetMaxBankAmountLeaveEmpty" placement="bottom" trigger={['focus']}>
              <CurrencyInput
                id="unsolicited-ratesheet-max-bank-amount"
                decimalSeparator="."
                value={unsolicitedRatesheetMaxBankAmount >= 0 ? unsolicitedRatesheetMaxBankAmount : ''}
                allowNegative={false}
                decimalScale={number.maximumFractionDigits}
                prefix={currencySymbol}
                placeholder="unsolicitedRatesheetMaxBankAmountPlaceholder"
                disabled={!isUnsolicitedRatesheetChecked}
                onValueChange={debounce(onMaxAmountChange, debounceMaxInputChange)}
                onFocus={(e) => e.target.select()}
              />
            </Tooltip>
          </div>
        </div>

        {isAutoQuoteFeaturesEnabledInUnsolicitedRatesheet && (
          <div className="settings-column">
            <ToggleAutoQuoting />
            <div className="bank-unsolicited-ratesheet-settings-max-amount">
              <label htmlFor="auto-quote-max-bank-amount">
                <FormattedMessage id="unsolicitedRatesheetAutoQuoteMaxBankAmountLabel" />
              </label>
              <Tooltip id="unsolicitedRatesheetAutoQuoteMaxBankAmountPopoverTitle" placement="top" trigger={['focus']}>
                <CurrencyInput
                  id="auto-quote-max-bank-amount"
                  decimalSeparator="."
                  value={autoQuoteMaxBankAmount >= 0 ? autoQuoteMaxBankAmount : ''}
                  allowNegative={false}
                  decimalScale={number.maximumFractionDigits}
                  prefix={currencySymbol}
                  placeholder="enterMaxAmount"
                  disabled={isAutoQuoteDisabled}
                  onValueChange={debounce(onAutoQuoteMaxAmountChange, debounceMaxInputChange)}
                  onFocus={(e) => e.target.select()}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </section>
    );
  },
);

UnsolicitedRatesheetSettingsComponent.propTypes = {
  hasUnsolicitedRatesheetLicence: propTypes.bool,
  isUnsolicitedRatesheetDisabled: propTypes.bool,
};

const mapStateToProps = (state) => {
  const hasUnsolicitedRatesheetLicence = hasLicences(licences.unsolicitedRatesheet)(state);
  const hasAutoQuoteLicence = hasLicences(licences.autoQuoting)(state);

  const tenantSettings = getSettings(state);

  const isUnsolicitedRatesheetDisabled =
    tenantSettings && 'unsolicitedRatesheetDisabled' in tenantSettings
      ? tenantSettings.unsolicitedRatesheetDisabled
      : false;

  const isAutoQuoteDisabled =
    tenantSettings && 'unsolicitedRatesheetDisabled' in tenantSettings ? tenantSettings.autoQuoteDisabled : false;

  return {
    user: getUser(state),
    hasUnsolicitedRatesheetLicence: hasUnsolicitedRatesheetLicence,
    isAutoQuoteFeaturesEnabledInUnsolicitedRatesheet: false && hasAutoQuoteLicence,
    isUnsolicitedRatesheetDisabled: !hasUnsolicitedRatesheetLicence || isUnsolicitedRatesheetDisabled,
    unsolicitedRatesheetMaxBankAmount: tenantSettings && tenantSettings.unsolicitedRatesheetMaxBankAmount,
    autoQuoteMaxBankAmount: tenantSettings && tenantSettings.autoQuoteMaxBankAmount,
    isAutoQuoteDisabled: !hasAutoQuoteLicence || isAutoQuoteDisabled,
  };
};

const mapDispatchToProps = { refreshSession: refreshSessionAction };

export const UnsolicitedRatesheetSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnsolicitedRatesheetSettingsComponent);
