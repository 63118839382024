import React from 'react';
import { FormattedNumber } from 'react-intl';
import { instrumentCodes } from '../../api/holding/codes';

export const columns = {
  code: { label: 'code' },
  amountInvested: { label: 'complianceAmountInvested' },
  investmentSharePct: { label: 'complianceInvestmentSharePct' },
  tradesCount: { label: 'tradesCount' },
};

export const presenter = (data, index) => {
  const currentInstrumentCode = data.code.toUpperCase();
  const label = instrumentCodes.find((instrumentCode) => instrumentCode.code === currentInstrumentCode)?.label;

  return (
    <tr key={index}>
      <td>{label || currentInstrumentCode}</td>
      <td>
        <FormattedNumber value={data.amountInvested} minimumFractionDigits={2} maximumFractionDigits={2} />
      </td>
      <td>
        <FormattedNumber value={data.investmentSharePct} minimumFractionDigits={2} maximumFractionDigits={2} />
      </td>
      <td>{data.tradesCount}</td>
    </tr>
  );
};
