import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Column, Protect, PulseBlob, roles, Row, SummaryItem } from '../../../components/common';
import { Money } from '../../money';

const TotalizerComponent = ({
  holding,
  changeView,
  session: { user },
  highlightIcon,
  hideButton,
  showInvestmentsNumber,
  ...props
}) => {
  const hasMaturingToday = holding.maturing.sum > 0;

  const summaryContent = (
    <SummaryItem
      top={<FormattedMessage id="maturingToday" />}
      middle={<Money value={holding.maturing.sum} short fraction={holding.maturing.sum > 0 ? 2 : 0} />}
      bottom={
        hasMaturingToday && (
          <Row alignItemsCenter>
            <span className="highlight">
              {showInvestmentsNumber ? (
                <FormattedMessage
                  tagName="span"
                  id="portfolio.filter-investments-number"
                  values={{ length: holding.maturing.count }}
                />
              ) : (
                <FormattedMessage
                  tagName="span"
                  id={holding.maturing.notDealtWith > 0 ? 'portfolio.actionRequired' : 'portfolio.viewTrades'}
                />
              )}
            </span>
            {highlightIcon && <PulseBlob />}
          </Row>
        )
      }
      highlight={hasMaturingToday}
      isLoading={holding.maturing.isFetching}
      {...props}
    />
  );

  return (
    <Column className="summary-item">
      {hasMaturingToday && !hideButton ? (
        <Protect user={user} requiredTo={roles.finance}>
          <button type="button" onClick={() => changeView()} className="action-button" data-testid="action-button">
            {summaryContent}
          </button>
        </Protect>
      ) : (
        summaryContent
      )}
    </Column>
  );
};

TotalizerComponent.propTypes = {
  holding: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  changeView: PropTypes.func,
  highlightIcon: PropTypes.bool,
  hideButton: PropTypes.bool,
  showInvestmentsNumber: PropTypes.bool,
};

TotalizerComponent.defaultProps = {
  changeView: () => {},
  highlightIcon: false,
  hideButton: false,
  showInvestmentsNumber: false,
};

const mapStateToProps = (state) => ({
  holding: state.holding,
  session: state.session,
  currency: state.session.user.currency,
});

export const MaturingTodayTotalizer = connect(mapStateToProps)(TotalizerComponent);
