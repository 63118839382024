import './ultimate-curve-details.scss';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Row } from 'react-display-flex';
import { injectIntl } from 'react-intl';

import { allTenors } from '../../../../../../api/holding/codes';
import toFloat from '../../../../../../parse-float';
import { ErrorBoundary } from '../../../../../app/ErrorBoundary';
import { Tenors } from '../../../../../Tenors/Tenors';
import { InvestorUltimateCurveChart } from './InvestorUltimateCurveChart';
import { InvestorUltimateCurveCreateTradePopover } from './InvestorUltimateCurveCreateTradePopover';
import { InvestorUltimateCurveTenorItem } from './InvestorUltimateCurveTenorItem';

export const InvestorUltimateCurveRatesheet = injectIntl(
  ({
    investorUnsolicitedRatesheets,
    intl,
    maturingSummary,
    onAcceptDefaultRatesheetConfirm,
    onAcceptBespokeRatesheetConfirm,
    onCreateRfqClick,
    onReinvestRfqClick,
  }) => {
    const [selectedTenor, setSelectedTenor] = useState(undefined);
    const [tenorItemRef, setTenorItemRef] = useState(false);

    const ultimateRatesheetRates = allTenors
      .map((tenor) => {
        const bestQuote = investorUnsolicitedRatesheets
          .flatMap(({ ratesheet }) => ratesheet.rates)
          .find((rate) => rate.tenor === tenor && rate.isBestQuote);

        if (!bestQuote) {
          return null;
        }

        return {
          tenor,
          rate: bestQuote?.rate,
          issuers: investorUnsolicitedRatesheets
            .filter(({ ratesheet }) => {
              const rateForTenor = ratesheet.rates.find((r) => r.tenor === tenor);

              return rateForTenor && rateForTenor.isBestQuote && rateForTenor.rate === bestQuote.rate;
            })
            .map(({ issuer }) => issuer),
        };
      })
      .filter(Boolean);

    const ultimateRatesheet = {
      rates: ultimateRatesheetRates,
    };

    const [selectedInvestorUnsolicitedRatesheet, setSelectedInvestorUnsolicitedRatesheet] = useState();
    const [investorUnsolicitedRatesheetsWithBestQuote, setInvestorUnsolicitedRatesheetsWithBestQuote] = useState([]);

    useEffect(() => {
      if (!selectedTenor) {
        setInvestorUnsolicitedRatesheetsWithBestQuote([]);
        setSelectedInvestorUnsolicitedRatesheet(undefined);
        return;
      }

      const filteredIssuers = investorUnsolicitedRatesheets.filter(({ ratesheet }) => {
        const rate = ratesheet.rates.find((rate) => rate.tenor === selectedTenor.tenor);
        return rate && rate.isBestQuote && rate.rate === selectedTenor.rate;
      });

      if (filteredIssuers.length === 1) {
        setSelectedInvestorUnsolicitedRatesheet(filteredIssuers[0]);
      }

      setInvestorUnsolicitedRatesheetsWithBestQuote(filteredIssuers);
    }, [selectedTenor, investorUnsolicitedRatesheets]);

    if (!ultimateRatesheetRates.length) {
      return null;
    }

    return (
      <ErrorBoundary>
        <Row element="section" aria-label="ultimate curve details" className="ultimate-curve-details">
          <Tenors
            readOnly={!!selectedTenor}
            status="highlight"
            ratesheet={ultimateRatesheet}
            selectedTenor={selectedTenor?.tenor}
            onClick={({ tenor, rate, ref }) => {
              setTenorItemRef(ref);
              setSelectedTenor({ tenor, rate });
            }}
            tenorItemComponent={InvestorUltimateCurveTenorItem}
          />
          <InvestorUltimateCurveChart
            readOnly={!!selectedTenor}
            data={ultimateRatesheetRates}
            investorUnsolicitedRatesheets={investorUnsolicitedRatesheets}
            onPointClick={({ tenor, rate, ref }) => {
              setTenorItemRef(ref);
              setSelectedTenor({ tenor, rate });
            }}
          />
          {selectedTenor && (
            <InvestorUltimateCurveCreateTradePopover
              tenor={selectedTenor?.tenor}
              rate={selectedTenor?.rate}
              show={!!selectedTenor}
              onHide={() => setSelectedTenor(undefined)}
              targetRef={tenorItemRef}
              investorUnsolicitedRatesheetsWithSameRates={investorUnsolicitedRatesheetsWithBestQuote}
              selectedInvestorUnsolicitedRatesheet={selectedInvestorUnsolicitedRatesheet}
              setSelectedInvestorUnsolicitedRatesheet={setSelectedInvestorUnsolicitedRatesheet}
              maturingSummary={maturingSummary}
              onCreateRfqClick={({ issuerId, maturities, principal, interestPaid, allocationCode }) => {
                setSelectedTenor(undefined);

                onCreateRfqClick({
                  issuerId,
                  maturities,
                  principal,
                  interestPaid,
                  allocationCode,
                });
              }}
              onReinvestRfqClick={(values) => {
                setSelectedTenor(undefined);

                onReinvestRfqClick(values);
              }}
              onSubmit={async ({
                allocationCode,
                interestPaid,
                principal,
                additionalFunds,
                rootTradeId,
                customMaturityDate,
                bankIssuerId,
              }) => {
                const reinvestmentProperties = rootTradeId && {
                  additionalFunds: additionalFunds ? toFloat(additionalFunds) : 0,
                  rootTradeId,
                  principal: undefined,
                };

                return selectedInvestorUnsolicitedRatesheet?.unsolicitedRatesheetEntityId
                  ? onAcceptBespokeRatesheetConfirm({
                      unsolicitedRatesheetEntityId: selectedInvestorUnsolicitedRatesheet.unsolicitedRatesheetEntityId,
                      allocationCode,
                      interestPaid,
                      principal: toFloat(principal),
                      bankIssuerId,
                      customMaturityDate,
                      rate: selectedTenor?.rate,
                      tenor: selectedTenor?.tenor,
                      ...reinvestmentProperties,
                    })
                  : onAcceptDefaultRatesheetConfirm({
                      allocationCode,
                      interestPaid,
                      principal: toFloat(principal),
                      bankIssuerId,
                      customMaturityDate,
                      rate: selectedTenor?.rate,
                      tenor: selectedTenor?.tenor,
                      ...reinvestmentProperties,
                    });
              }}
            />
          )}
        </Row>
      </ErrorBoundary>
    );
  },
);

InvestorUltimateCurveRatesheet.propTypes = {
  maturingSummary: PropTypes.shape(),
  investorUnsolicitedRatesheets: PropTypes.arrayOf(
    PropTypes.shape({
      ratesheet: PropTypes.shape({
        rates: PropTypes.arrayOf(
          PropTypes.shape({
            tenor: PropTypes.string,
            rate: PropTypes.number,
            isBestQuote: PropTypes.bool,
          }),
        ),
      }),
      issuer: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        shortRating: PropTypes.string,
      }),
    }),
  ),
};
