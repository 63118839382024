import './datepicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useRef } from 'react';

import classNames from 'classnames';
import enAU from 'date-fns/locale/en-AU';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Column } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';

import { enAuShortDateFormat, holidaysInSurroundingYears, isValidDate, isWeekDay } from '../../../date';
import { isTabPressed } from '../../../is-tab-pressed';

registerLocale('en-AU', enAU);
registerLocale('en-US', enAU);

const reactDatepickerDayClassName = 'react-datepicker__day';

export const DatePicker = ({
  value,
  labelId,
  borderBottomOnly,
  disableWeekends,
  disableHolidays,
  includeDateIntervals,
  wrapperClassName,
  ...props
}) => {
  const datePickerRef = useRef();

  return (
    <Column
      justifyContentSpaceEvenly
      className={classNames('imperium-datepicker', wrapperClassName, {
        'border-bottom-only': borderBottomOnly,
      })}
    >
      {labelId && (
        <p>
          <FormattedMessage tagName="span" id={labelId} />
        </p>
      )}
      <ReactDatePicker
        autoComplete="off"
        onKeyDown={(event) => {
          if (!isTabPressed(event)) {
            return;
          }

          const isDayPickerTheFocusedElement = document.activeElement.classList.contains(reactDatepickerDayClassName);

          if (!isDayPickerTheFocusedElement) {
            return;
          }

          datePickerRef.current.setOpen(false);
        }}
        selected={isValidDate(value) ? new Date(value) : null}
        filterDate={disableWeekends && isWeekDay}
        excludeDates={disableHolidays && holidaysInSurroundingYears}
        includeDateIntervals={includeDateIntervals}
        ref={datePickerRef}
        dateFormat="P"
        placeholderText={enAuShortDateFormat}
        todayButton={<FormattedMessage id="today" />}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale="en-AU"
        {...props}
      />
      {!props.inline && (
        <i
          onClick={() => {
            datePickerRef.current.setOpen(true);
          }}
          className="icon-calendario"
        />
      )}
    </Column>
  );
};

export default DatePicker;
