import React from 'react';

import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { hasLicences } from '../../actions/session-selector';
import { licences } from '../../api/licences';
import {
  getInvestorsUnsolicitedRatesheetsUrl,
  getInvestorUnsolicitedRatesheets,
} from '../../api/unsolicited-ratesheets/investor/get-investor-actionable-unsolicited-ratesheets';

export const withUnsolicitedRatesheets = (Component) => (props) => {
  const hasUnsolicitedRatesheetLicence = useSelector((state) => hasLicences(licences.unsolicitedRatesheet)(state));

  if (!hasUnsolicitedRatesheetLicence) {
    return <Component {...props} />;
  }

  const { data: investorUnsolicitedRatesheets } = useSWR(
    getInvestorsUnsolicitedRatesheetsUrl,
    getInvestorUnsolicitedRatesheets,
  );
  return <Component {...props} investorUnsolicitedRatesheets={investorUnsolicitedRatesheets} />;
};
