import React, { useEffect } from 'react';

import { Formik } from 'formik';
import { func, number, shape, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import useSWR from 'swr';
import * as Yup from 'yup';

import { interests } from '../../../../../../api/holding/codes';
import { getTradesMaturingToday, getTradesMaturingTodayUrl } from '../../../../../../api/holding/maturing-today';
import {
  ConfirmationPopover,
  FormikCurrencyInput,
  FormikSelectField,
  ValidatedFormikField,
} from '../../../../../../components/common';
import { sameDayAsToday } from '../../../../../../date';
import { toCurrency, toNumberFormat } from '../../../../../../format-numbers';
import toFloat from '../../../../../../parse-float';
import { ImperiumIntlProvider } from '../../../../../intl-provider';
import { splitTenor } from '../../../../../Tenors/TenorItem';

export const buildInitialFormValues = ({ issuer }) => ({
  additionalFunds: '',
  bankIssuerId: issuer ? issuer.id : '',
});

export const CollationOfRatesheetsReinvestmentForm = injectIntl(
  ({
    hasSeparationOfDuties,
    tenor,
    rate,
    issuer,
    onCancelClick,
    onCreateTradeSubmit,
    popoverContainer,
    onReinvestRfqClick,
    unsolicitedRatesheetMaxBankAmount,
    children,
    ratesheet,
    intl,
  }) => {
    const { data: maturingTradesToday } = useSWR(getTradesMaturingTodayUrl, getTradesMaturingToday);

    const { time, unit } = splitTenor(tenor);

    const isStale = ratesheet && !sameDayAsToday(ratesheet.updatedAt);

    const isDealDisabled =
      isStale ||
      unsolicitedRatesheetMaxBankAmount === 0 ||
      unsolicitedRatesheetMaxBankAmount === null ||
      unsolicitedRatesheetMaxBankAmount === undefined ||
      hasSeparationOfDuties;

    return (
      <ImperiumIntlProvider>
        <Formik
          validationSchema={buildValidationSchema({
            intl,
            maturingTradesToday,
            unsolicitedRatesheetMaxBankAmount,
            hasSeparationOfDuties,
          })}
          onSubmit={onCreateTradeSubmit}
          initialValues={buildInitialFormValues({ issuer })}
        >
          {(formikProps) => {
            const { errors, touched, isSubmitting, dirty, isValid, handleSubmit, values, setFieldValue } = formikProps;

            const isDisabled = isSubmitting || !dirty || !isValid;
            const isRequestRfqDisabled =
              !values.rootTradeId || !values.interestPaid || !values.allocationCode || !values.bankIssuerId;
            const selectedSourceTrade = maturingTradesToday?.find(({ id }) => id === values.rootTradeId);

            useEffect(() => {
              if (issuer?.id && values.bankIssuerId !== issuer.id) {
                setFieldValue('bankIssuerId', issuer.id);
              }
            }, [values, setFieldValue]);

            return (
              <Column>
                <Column>
                  <ValidatedFormikField
                    autoFocus
                    name="rootTradeId"
                    labelId="sourceTrade"
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={maturingTradesToday
                      ?.filter(({ maturityAction }) => !maturityAction)
                      .map(({ id: value, principal, issuer }) => ({
                        value,
                        label: `${issuer.name} - ${toNumberFormat({ value: principal })}`,
                      }))}
                    onChange={async (value) => {
                      const maturingTrade = maturingTradesToday.find(({ id }) => id === value);

                      await setFieldValue('allocationCode', maturingTrade.allocationCode, true);
                      await setFieldValue('interestPaid', maturingTrade.interestPaidCode, true);
                    }}
                    isLoading={!maturingTradesToday}
                  />
                  <ValidatedFormikField
                    name="interestPaid"
                    labelId="selectInterestPaid"
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={interests.map(({ code: value, label: id }) => ({
                      value,
                      label: intl.formatMessage({ id }),
                    }))}
                  />
                  <ValidatedFormikField
                    name="allocationCode"
                    labelId="holdingAllocationCode"
                    touched={touched}
                    errors={errors}
                    disabled
                  />
                  <ValidatedFormikField
                    name="additionalFunds"
                    labelId="additionalFunds"
                    component={FormikCurrencyInput}
                    allowNegative={false}
                    touched={touched}
                    errors={errors}
                    aria-label="additional funds"
                    onFocus={(e) => e.target.select()}
                  />
                  {children && children(formikProps)}
                </Column>
                <Row className="actions" justifyContentEnd>
                  {isDealDisabled ? null : (
                    <ConfirmationPopover
                      buttonLabelId="unsolicitedRatesheetDeal"
                      confirmationMessageId="unsolicitedRatesheetDealReinvestmentConfirmation"
                      confirmationMessageValues={{
                        sourceTrade:
                          selectedSourceTrade &&
                          `${selectedSourceTrade.issuer.name} ${toCurrency({ value: selectedSourceTrade.principal })}`,
                        b: (msg) => <b>{msg}</b>,
                      }}
                      disabled={isDisabled}
                      onConfirm={handleSubmit}
                      className="btn-solid-primary"
                      popoverClass="light"
                      isConfirmingFromOutside={isSubmitting}
                      container={popoverContainer?.current}
                    />
                  )}
                  <ConfirmationPopover
                    buttonLabelId="unsolicitedRatesheetRequestRfq"
                    confirmationMessageId="areYouSure"
                    popoverClass="light"
                    className="btn-solid-primary"
                    disabled={isRequestRfqDisabled}
                    container={popoverContainer?.current}
                    onConfirm={() => {
                      onReinvestRfqClick({
                        id: values.rootTradeId,
                        issuerId: issuer.id,
                        maturities: [
                          {
                            time,
                            unit,
                          },
                        ],
                        interestPaid: values.interestPaid,
                        additionalFunds: values.additionalFunds,
                        allocationCode: values.allocationCode,
                      });
                    }}
                  />
                  <Button onClick={onCancelClick}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </Row>
              </Column>
            );
          }}
        </Formik>
      </ImperiumIntlProvider>
    );
  },
);

CollationOfRatesheetsReinvestmentForm.propTypes = {
  onCreateTradeSubmit: func,
  onCancelClick: func,
  onReinvestRfqClick: func,
  popoverContainer: shape(),
  tenor: string,
  rate: number,
  unsolicitedRatesheetMaxBankAmount: number,
  issuer: shape(),
  intl: shape(),
  children: func,
  ratesheet: shape(),
};

const buildValidationSchema = ({
  intl,
  unsolicitedRatesheetMaxBankAmount,
  maturingTradesToday,
  hasSeparationOfDuties,
}) => {
  const baseSchema = {
    rootTradeId: Yup.number().required(intl.formatMessage({ id: 'required' })),
    interestPaid: Yup.string().required(intl.formatMessage({ id: 'required' })),
    allocationCode: Yup.string().required(intl.formatMessage({ id: 'required' })),
    bankIssuerId: Yup.string().required(intl.formatMessage({ id: 'required' })),
    additionalFunds: Yup.string(),
  };

  if (!hasSeparationOfDuties && unsolicitedRatesheetMaxBankAmount > 0 && maturingTradesToday?.length) {
    baseSchema.rootTradeId = baseSchema.rootTradeId.test(
      'maxPrincipal',
      intl.formatMessage({ id: 'unsolicitedRatesheetMaxAmountReached' }),
      function (value) {
        const maturingTrade = maturingTradesToday.find(({ id }) => id === this.parent.rootTradeId);
        return maturingTrade ? toFloat(maturingTrade.principal) <= unsolicitedRatesheetMaxBankAmount : true;
      },
    );

    baseSchema.additionalFunds = baseSchema.additionalFunds.test(
      'maxAmountReachedWithAdditionalFundsPrincipal',
      intl.formatMessage({ id: 'unsolicitedRatesheetMaxAmountReachedWithAdditionalFunds' }),
      function (value) {
        const maturingTrade = maturingTradesToday.find(({ id }) => id === this.parent.rootTradeId);
        let principal = this.parent.additionalFunds ? toFloat(value) : 0;

        if (maturingTrade && maturingTrade.principal) {
          principal += maturingTrade.principal;
        }

        return maturingTrade ? toFloat(principal) <= unsolicitedRatesheetMaxBankAmount : true;
      },
    );
  }

  return Yup.object().shape(baseSchema);
};
