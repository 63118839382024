const { env } = require('../env');

module.exports = {
  env: {
    apiUrl: env.REACT_APP_API_SERVICE_URL,
    onboardingApiUrl: env.REACT_APP_ONBOARDING_API_URL,
    socketServerUrl: env.REACT_APP_SOCKET_SERVER_URL,
    logRocketKey: env.LOGROCKET_KEY || '5so5rj/imperium',
    tenantRegion: env.TENANT_REGION || 'AU',
    countryState: env.COUNTRY_STATE || 'NSW',
    nodeEnv: env.NODE_ENV,
    virtualHost: env.VIRTUAL_HOST,
    googleAnalyticsKey: env.GOOGLE_ANALYTICS_KEY || 'UA-145421445-1',
    maxRfqIssuerLimit: env.MAX_RFQ_ISSUER_LIMIT || 10,
    rfqReviewDatesRange: env.REACT_APP_RFQ_REVIEW_DATES_RANGE || 7,
  },
};
