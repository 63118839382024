import { createSelector } from 'reselect';

import { licences as availableLicences } from '../components/common/Protect';
import { userHasAccessByRole } from '../components/common/Protect/user-has-access-by-role';
import { isPast } from '../date';
import userStore from '../user-store';

export const getUser = (state) => state.session.user;

export const getCurrency = createSelector(getUser, (user) => user && user.currency);

export const hasDefaultTPlusNDaysSelector = createSelector(getUser, (user) => user.defaultTPlusNDays > 0);

export const hasSeparationOfDuties = createSelector(getUser, (user) => user.enforceSeparationOfDuties);

export const isAdmin = createSelector(
  getUser,
  (user) => user && (user.tenantRole === 'admin' || user.globalRole === 'admin'),
);

export const isBank = createSelector(getUser, (user) => user && user.isBank);

export const isGlobalAdmin = createSelector(getUser, (user) => user && user.globalRole === 'admin');

export const hasRequiredRole = (requiredTo) =>
  createSelector(getUser, (user) => user && userHasAccessByRole({ ...user, requiredTo }));

export const hasLicences = (...licences) =>
  createSelector(
    getUser,
    (user) =>
      user &&
      user.licences &&
      licences.every((licence) => user.licences.find((userLicence) => userLicence === licence)),
  );

export const isTenantExecutionOnly = createSelector(
  getUser,
  (user) => user && user.licences && !user.licences.includes(availableLicences.portfolio),
);

export const getLatestTermDepositRatesheetUpdatedAt = createSelector(
  getUser,
  (user) => user && user.latestTermDepositRatesheetUpdatedAt,
);

export const getSettings = createSelector(getUser, (user) => user && user.tenantSettings);

const root = '/';
const dashboardRoot = '/dashboard';
const outgoingRfqsRoot = '/portfolio/rfqs';
const changePasswordRoot = '/admin/user/change-password';

export const getRootPath = createSelector(getUser, (user) => {
  if (!user) {
    return root;
  }

  const token = userStore.getToken();

  if (!token) {
    return root;
  }

  if (!user || !user.licences) {
    return root;
  }

  if (isPast(user.passwordExpiresAt)) {
    return changePasswordRoot;
  }

  const { licences, tenantRole, isBank } = user;

  const hasPortfolio = licences && licences.includes('PORTFOLIO');
  const hasDashboard = licences && licences.includes('DASHBOARD');

  if (tenantRole === 'viewer' && hasPortfolio) return root;

  if (isBank && hasDashboard) {
    return dashboardRoot;
  }

  return hasPortfolio ? root : outgoingRfqsRoot;
});
