import './unsolicited-ratesheet-customers.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import { compose } from 'redux';
import useSWR from 'swr';

import { fetchRatesheets } from '../../../../../../actions/ratesheets';
import { getSettings } from '../../../../../../actions/session-selector';
import { segmentCodes } from '../../../../../../api/issuers/codes';
import rooms from '../../../../../../api/socket/rooms';
import {
  getIssuerCustomers,
  getIssuerCustomersUrl,
} from '../../../../../../api/unsolicited-ratesheets/issuer/get-issuer-customers';
import {
  buildGetIssuersUnsolicitedRatesheetsUrl,
  getIssuerUnsolicitedRatesheets,
} from '../../../../../../api/unsolicited-ratesheets/issuer/get-issuer-unsolicited-ratesheets';
import { sendBespokeRatesheetToInvestors } from '../../../../../../api/unsolicited-ratesheets/issuer/send-bepoke-ratesheet-to-investors';
import { Column, InputSearch, Range, renderSelect, ResultsPresenter } from '../../../../../../components/common';
import includeSocket from '../../../../../../components/hoc/include-socket';
import { withNavigate } from '../../../../../../components/hoc/with-router-properties';
import { today } from '../../../../../../date';
import { getBankRatesheet } from '../../../../../../ducks/ratesheet/selectors';
import { getMoneySymbol } from '../../../../../money';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../../../../../toast/toast';
import { customerTypes, UnsolicitedRatesheetBespokeType } from '../unsolicited-ratesheet-models';
import { defaultRatesheet } from './default-ratesheet';
import { SendRatesheetDialog } from './SendRatesheetDialog';
import { columns, presenter } from './unsolicited-ratesheet-customers-presenter';
import { UnsolicitedRatesheetDetails } from './UnsolicitedRatesheetDetails';
import { UnsolicitedRatesheetsCustomerHeader } from './UnsolicitedRatesheetsCustomerHeader';

const mapStateToProps = (state) => {
  const tenantSettings = getSettings(state);

  const isUnsolicitedRatesheetDisabled =
    tenantSettings && 'unsolicitedRatesheetDisabled' in tenantSettings
      ? tenantSettings.unsolicitedRatesheetDisabled
      : false;

  return {
    currency: state.session.user.currency,
    tenantIssuerId: state.session.user.tenantIssuerId,
    bankRatesheet: getBankRatesheet(state),
    isUnsolicitedRatesheetDisabled: isUnsolicitedRatesheetDisabled,
  };
};

const mapDispatchToProps = {
  fetchRatesheets,
};

const initialFilter = {};

const rangeInitialFilter = {
  from: today().toDate(),
  to: today().toDate(),
};

export const UnsolicitedRatesheetCustomersComponent = ({
  bankRatesheet,
  currency,
  isUnsolicitedRatesheetDisabled,
  tenantIssuerId,
  intl,
}) => {
  const [rangeFilter, setRangeFilter] = useState(rangeInitialFilter);
  const [selectedCustomersTenantsIds, setSelectedCustomersTenantsIds] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [showSendRatesheetDialog, setShowSendRatesheetDialog] = useState(false);
  const [isConfirmingBespokeRatesheet, setIsConfirmingBespokeRatesheet] = useState(false);
  const [ratesheetBySegmentCode, setRatesheetBySegmentCode] = useState(defaultRatesheet);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [customerCurrentRatesheet, setCustomerCurrentRatesheet] = useState();

  const { data, error } = useSWR(getIssuerCustomersUrl, getIssuerCustomers);
  const {
    data: unsolicitedRatesheets,
    mutate: mutateUnsolicitedRatesheets,
    isValidating: isUnsolicitedRatesheetLoading,
  } = useSWR(
    data &&
      buildGetIssuersUnsolicitedRatesheetsUrl({
        tenantsIds: data.map(({ investorTenantId }) => investorTenantId),
        from: rangeFilter.from,
        to: rangeFilter.to,
        includeParticipated: true,
      }),
    getIssuerUnsolicitedRatesheets,
  );

  useEffect(() => {
    if (!selectedCustomersTenantsIds || !selectedCustomersTenantsIds.length || !bankRatesheet) {
      return;
    }

    const selectedCustomers = data.filter(({ investorTenantId }) =>
      selectedCustomersTenantsIds.includes(investorTenantId),
    );

    const [{ segmentCode: baseSegmentCode }] = selectedCustomers;

    const customerBySegment = selectedCustomers.every(({ segmentCode }) => segmentCode === baseSegmentCode);

    if (!customerBySegment) {
      setRatesheetBySegmentCode(defaultRatesheet);

      return;
    }

    setRatesheetBySegmentCode(bankRatesheet.TD.find(({ segmentCode }) => segmentCode === baseSegmentCode));
  }, [selectedCustomersTenantsIds, data, bankRatesheet]);

  useEffect(() => {
    if (!data) {
      return;
    }

    let nextCustomers = Array.from(data);

    if (unsolicitedRatesheets) {
      nextCustomers = nextCustomers.map((customer) => {
        const investorUnsolicitedRatesheets = unsolicitedRatesheets.filter(
          ({ investorTenantId }) => investorTenantId === customer.investorTenantId,
        );

        if (!investorUnsolicitedRatesheets.length) {
          return customer;
        }

        const [
          {
            ratesheet: currentRatesheet,
            entityId: unsolicitedRatesheetEntityId,
            expiresAt,
            type: unsolicitedRatesheetType,
          },
        ] = investorUnsolicitedRatesheets.sort(
          ({ expiresAt: leftExpiresAt }, { expiresAt: rightExpiresAt }) =>
            +new Date(rightExpiresAt) - +new Date(leftExpiresAt),
        );

        const tradesFromUnsolicitedRatesheets = investorUnsolicitedRatesheets
          .flatMap(({ type: unsolicitedRatesheetType, entityId: unsolicitedRatesheetEntityId, ratesheet, trades }) =>
            trades.map((trade) => ({ ...trade, ratesheet, unsolicitedRatesheetEntityId, unsolicitedRatesheetType })),
          )
          .sort(
            ({ createdAt: leftExpiresAt }, { createdAt: rightCreatedAt }) =>
              +new Date(rightCreatedAt) - +new Date(leftExpiresAt),
          );

        const nextCustomer = {
          ...customer,
          unsolicitedRatesheetEntityId,
          unsolicitedRatesheetType,
          currentRatesheet: {
            expiresAt,
            ...currentRatesheet,
          },
        };

        return investorUnsolicitedRatesheets.length
          ? {
              ...nextCustomer,
              trades: tradesFromUnsolicitedRatesheets,
            }
          : nextCustomer;
      });
    }

    if (filter.customer) {
      nextCustomers = nextCustomers.filter(
        ({ name }) => !filter.customer || name.match(new RegExp(filter.customer, 'i')),
      );
    }

    if (filter.segment) {
      nextCustomers = nextCustomers.filter(({ segmentCode }) => segmentCode.match(new RegExp(filter.segment, 'i')));
    }

    if (filter.customerType) {
      nextCustomers = nextCustomers.filter(({ customerType }) =>
        customerType.match(new RegExp(filter.customerType, 'i')),
      );
    }

    if (filter.hasTrades) {
      nextCustomers = nextCustomers.filter(({ trades }) => trades);
    }

    setCustomers(nextCustomers);
  }, [data, unsolicitedRatesheets, filter]);

  const memoizedSelectedCustomer = useMemo(() => selectedCustomer, [selectedCustomer]);

  useEffect(() => {
    if (!memoizedSelectedCustomer) {
      return;
    }

    const nextSelectedCustomer = customers.find(
      (customer) => customer.investorTenantId === memoizedSelectedCustomer.investorTenantId,
    );

    setSelectedCustomer(nextSelectedCustomer);
  }, [customers, memoizedSelectedCustomer]);

  const updateCustomerCurrentRatesheet = useCallback(
    (customer) => {
      const currentCustomer = customers.find(({ investorTenantId }) => investorTenantId === customer.investorTenantId);

      const investorUnsolicitedRatesheets = unsolicitedRatesheets?.filter(
        ({ investorTenantId }) => investorTenantId === customer.investorTenantId,
      );
      const isThereAnyBespokeUnsolicitedRatesheets = investorUnsolicitedRatesheets?.find(
        ({ type }) => type === UnsolicitedRatesheetBespokeType,
      );

      const currentRatesheetType: UnsolicitedRatesheetTypes = isThereAnyBespokeUnsolicitedRatesheets
        ? UnsolicitedRatesheetBespokeType
        : currentCustomer.customerType;

      const currentRatesheet =
        currentRatesheetType === UnsolicitedRatesheetBespokeType
          ? currentCustomer.currentRatesheet
          : bankRatesheet.TD.find(({ segmentCode }) => segmentCode === customer.segmentCode);

      setCustomerCurrentRatesheet({
        type: currentRatesheetType,
        ratesheet: currentRatesheet,
        unsolicitedRatesheetEntityId: currentCustomer.unsolicitedRatesheetEntityId,
      });
    },
    [bankRatesheet, unsolicitedRatesheets, customers],
  );

  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }

    updateCustomerCurrentRatesheet(selectedCustomer);
  }, [selectedCustomer, updateCustomerCurrentRatesheet]);

  const buildSegmentsOptions = () =>
    segmentCodes.map(({ label, code: value }) => ({
      label: intl.formatMessage({ id: label }),
      value,
    }));

  const onSendBespokeRatesheets = async (ratesheet) => {
    try {
      setIsConfirmingBespokeRatesheet(true);

      await sendBespokeRatesheetToInvestors({
        investorsTenantsIds: [...new Set(selectedCustomersTenantsIds)],
        ratesheet,
      });

      mutateUnsolicitedRatesheets();

      showToastMessage(intl.formatMessage({ id: 'sendBespokeRatesheetsSuccessfully' }), MessageType.SUCCESS);
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setIsConfirmingBespokeRatesheet(false);
      setShowSendRatesheetDialog(false);
    }
  };

  const filterByCustomer = (event) => setFilter(() => ({ ...filter, customer: event.target.value }));

  const renderMaturitiesFilter = ({ isUnsolicitedRatesheetDisabled }) => (
    <Row
      aria-label="customers filters"
      element="fieldset"
      className="unsolicited-ratesheet-filter"
      wrap
      disabled={isUnsolicitedRatesheetDisabled}
    >
      <Row justifyContentSpaceBetween>
        <div className="month-picker">
          <div className="monthly-report-selector">
            <Range
              from={rangeFilter.from}
              to={rangeFilter.to}
              onChangeFrom={(from) => setRangeFilter(() => ({ ...rangeFilter, from }))}
              onChangeTo={(to) => setRangeFilter(() => ({ ...rangeFilter, to }))}
            />
          </div>
        </div>
        {renderSelect({
          name: 'segment',
          label: 'segment',
          isClearable: true,
          onDropdownFilterChange: (property, value) => setFilter(() => ({ ...filter, segment: value })),
          options: buildSegmentsOptions(),
          className: 'filter-item',
        })}
        {renderSelect({
          name: 'customerType',
          label: 'customerType',
          isClearable: true,
          onDropdownFilterChange: (property, value) => setFilter(() => ({ ...filter, customerType: value })),
          options: buildCustomerTypeOptions({ intl }),
          className: 'filter-item',
        })}
        <div className="customer-filter filter-item">
          <p className="grey-color text-ellipsis">
            <FormattedMessage id="customer" />
          </p>
          <InputSearch
            defaultValue={filter.customer}
            onChange={filterByCustomer}
            onClear={() => setFilter(() => ({ ...filter, customer: undefined }))}
          />
        </div>
        <div className="customer-filter filter-item">
          <p className="grey-color text-ellipsis">
            <FormattedMessage id="hasTrades" />
          </p>
          <Toggle
            checked={filter.hasTrades}
            icons={false}
            onChange={({ target }) => {
              const { checked: hasTrades } = target;

              setFilter(() => ({ ...filter, hasTrades }));
            }}
          />
        </div>

        <Button
          onClick={() => {
            setFilter(initialFilter);
            setRangeFilter(rangeInitialFilter);
          }}
        >
          <FormattedMessage id="reset" />
        </Button>
      </Row>
      <Row>
        <Button
          onClick={() => {
            setShowSendRatesheetDialog(true);
          }}
          disabled={!selectedCustomersTenantsIds.length}
        >
          <FormattedMessage tagName="span" id="sendBespokeRatesheets" />
        </Button>
      </Row>
    </Row>
  );

  const onCheckboxChange = ({ target: { id, checked } }) => {
    const nextSelectedCustomers = [...selectedCustomersTenantsIds];

    if (checked) {
      return setSelectedCustomersTenantsIds(nextSelectedCustomers.concat(+id));
    }

    nextSelectedCustomers.splice(
      selectedCustomersTenantsIds.findIndex((investorTenantId) => investorTenantId === +id),
      1,
    );

    setSelectedCustomersTenantsIds(nextSelectedCustomers);
  };

  const onContextSendBespokeRatesheets = ({ investorTenantId }) => {
    setSelectedCustomersTenantsIds([investorTenantId]);
    setShowSendRatesheetDialog(true);
  };

  const isSelectAllChecked =
    selectedCustomersTenantsIds.length > 0 &&
    selectedCustomersTenantsIds.length === data?.length &&
    data.every(({ investorTenantId }) => selectedCustomersTenantsIds.includes(investorTenantId));

  const noRecords = isUnsolicitedRatesheetDisabled || !customers || !customers.length;

  const currencySymbol = getMoneySymbol({ currency, short: true });
  const presenterBuilderMetadata = {
    isFetching: false,
    noRecords,
    noRecordsMessageId: isUnsolicitedRatesheetDisabled ? 'unsolicitedRatesheetDisabledNoRecords' : 'noRecords',
    recordsPresenter: {
      data: customers,
      presenter,
      columns: columns({ currencySymbol, isSelectAllChecked }),
      actions: {
        onCustomerCheckboxChange: onCheckboxChange,
        onDetailsClick: setSelectedCustomer,
        onContextSendBespokeRatesheets,
      },
      onSelectAllChange: ({ target: { checked } }) =>
        setSelectedCustomersTenantsIds(checked ? data.map(({ investorTenantId }) => investorTenantId) : []),
      options: {
        selectedCustomers: selectedCustomersTenantsIds,
        isLoadingTrades: !unsolicitedRatesheets,
      },
    },
    filter: {
      component: renderMaturitiesFilter({ isUnsolicitedRatesheetDisabled }),
    },
    hasError: !!error,
  };

  const onAddOnBehalfConfirm = () => {
    mutateUnsolicitedRatesheets();
    showToastMessage(intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);
  };

  return (
    <section
      aria-label="unsolicited ratesheets section"
      className={classNames('unsolicited-ratesheet-container', { 'is-loading': !data })}
    >
      {ratesheetBySegmentCode && (
        <SendRatesheetDialog
          show={showSendRatesheetDialog}
          ratesheet={ratesheetBySegmentCode}
          onConfirm={onSendBespokeRatesheets}
          isConfirming={isConfirmingBespokeRatesheet}
          onCancel={() => {
            setShowSendRatesheetDialog(false);
          }}
        />
      )}
      <UnsolicitedRatesheetsCustomerHeader isLoading={!unsolicitedRatesheets} customers={customers} />
      <Column className="customers">
        <ResultsPresenter {...presenterBuilderMetadata} />
      </Column>
      <UnsolicitedRatesheetDetails
        customerCurrentRatesheet={customerCurrentRatesheet}
        selectedCustomer={selectedCustomer}
        onAddOnBehalfConfirm={onAddOnBehalfConfirm}
        onRatesheetUpdate={mutateUnsolicitedRatesheets}
        onClose={() => setSelectedCustomer(undefined)}
        isUnsolicitedRatesheetLoading={isUnsolicitedRatesheetLoading}
        onSendBespokeRatesheets={onContextSendBespokeRatesheets}
      />
    </section>
  );
};

const buildCustomerTypeOptions = ({ intl }) =>
  customerTypes.map((value) => ({
    label: intl.formatMessage({ id: value }),
    value,
  }));

UnsolicitedRatesheetCustomersComponent.propTypes = {
  bankRatesheet: PropTypes.shape(),
  currency: PropTypes.string.isRequired,
  isUnsolicitedRatesheetDisabled: PropTypes.bool,
};

UnsolicitedRatesheetCustomersComponent.defaultProps = {
  globalRole: '',
};

export const UnsolicitedRatesheetCustomers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  includeSocket({ rooms: [rooms.offers] }),
  injectIntl,
  withNavigate,
)(UnsolicitedRatesheetCustomersComponent);
