import './home.scss';

import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as counterpartyActions from '../../actions/compliances/counterparty';
import * as creditActions from '../../actions/compliances/credit';
import * as maturityActions from '../../actions/compliances/maturity';
import * as holdingActions from '../../actions/holding/holding';
import * as quotesActions from '../../actions/holding/rfq/quotes';
import * as rfqActions from '../../actions/holding/rfq/rfq';
import * as portfolioActions from '../../actions/portfolio';
import * as sessionActions from '../../actions/session';
import { hasDefaultTPlusNDaysSelector } from '../../actions/session-selector';
import { sendFeedbackEmail } from '../../api/feedback/send-feedback-email';
import rooms from '../../api/socket/rooms';
import { socketEvents } from '../../api/socket/setup-socket';
import {
  Column,
  InformationDropdown,
  licences,
  Loadable,
  MainTabs,
  Protect,
  roles,
  Row,
  Tabs as PrimaryTabs,
  Tour,
  tourElements,
} from '../../components/common';
import { Forbidden } from '../../components/error/403-forbidden';
import { FeedbackDialog } from '../../components/feedback/FeedbackDialog';
import includeSocket from '../../components/hoc/include-socket';
import { withLocation, withNavigate, withParams, withSearchParams } from '../../components/hoc/with-router-properties';
import { formatToShortDate, isValidDate, today } from '../../date';
import { listUnsettledTradesAction } from '../../ducks/unsettled';
import userStore from '../../user-store';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../toast/toast';
import { RfqTabBadge } from './RfqTabBadge';

const HoldingsTab = React.lazy(() => import('./Tabs/HoldingsTab'));
const ComplianceTab = React.lazy(() => import('./Tabs/ComplianceTab'));
const ReportsTab = React.lazy(() => import('./Tabs/ReportsTab'));
const RfqsTab = React.lazy(() => import('./Tabs/RfqsTab'));
const SandboxTab = React.lazy(() => import('./Tabs/SandboxTab'));

function mapStateToProps(state) {
  return {
    allocation: state.allocation,
    assets: state.assets,
    counterparty: state.counterparty,
    credit: state.credit,
    holding: state.holding,
    maturity: state.maturity,
    unsettled: state.unsettledTrades,
    portfolio: state.portfolio,
    quotes: state.quotes,
    maturing: state.maturing,
    session: state.session,
    rfq: state.rfq,
    browserLocale: state.browserLocale,
    hasDefaultTPlusNDays: hasDefaultTPlusNDaysSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      counterparty: bindActionCreators(counterpartyActions, dispatch),
      credit: bindActionCreators(creditActions, dispatch),
      maturity: bindActionCreators(maturityActions, dispatch),
      holding: bindActionCreators(holdingActions, dispatch),
      portfolio: bindActionCreators(portfolioActions, dispatch),
      quotes: bindActionCreators(quotesActions, dispatch),
      session: bindActionCreators(sessionActions, dispatch),
      rfq: bindActionCreators(rfqActions, dispatch),
      listUnsettledTradesAction: bindActionCreators(listUnsettledTradesAction, dispatch),
    },
  };
}

export const holdingsTabKey = 'holdings';
export const rfqsTabKey = 'rfqs';
const baseRoute = '/portfolio';
const compliance = 'compliance';
const reports = 'reports';
const sandbox = 'sandbox';
const current = 'current';

const isRfqOpened = (status, confirmed) => status === 'open' || (status === 'closed' && confirmed === false);

export class HomePage extends Component {
  constructor(props) {
    super(props);
    const { params } = props;

    const defaultKey =
      params &&
      params.primaryTab &&
      [holdingsTabKey, rfqsTabKey, compliance, reports, sandbox].includes(params.primaryTab)
        ? params.primaryTab
        : holdingsTabKey;

    this.state = {
      currentTab: defaultKey,
      currentSecondaryTab: {
        holdings: null,
        rfqs: null,
        compliance: null,
      },
      isFeedbackDialogOpen: false,
      isLoadingFeedback: false,
    };

    this.tourChild = React.createRef();
  }

  componentDidMount() {
    const { searchParams } = this.props;

    const date = searchParams.get('date');

    this.updateSelectedDate(date);
    this.fetchPortfolioHeaderData(date);
    this.fetchUnsettledTrades();

    this.props.on(socketEvents.rfqUpdated, () => {
      this.props.actions.quotes.fetchRfqs({ silentFetch: true });
      this.fetchUnsettledTrades();
      this.props.actions.holding.fetchHolding();
    });
  }

  componentDidUpdate(previousProps) {
    this.fetchProactiveDataIfNeeded(previousProps, this.props);
    this.validateCapitalBasis(previousProps, this.props);

    this.updatePrimaryTabByLocationState();
  }

  updatePrimaryTabByLocationState() {
    const { location } = this.props;
    const { currentTab } = this.state;

    if (!location.state || !location.state.primaryTab || currentTab === location.state.primaryTab) {
      return;
    }

    this.setState({ currentTab: location.state.primaryTab });
  }

  onDateChange = (date) => {
    ['portfolio', 'holding', 'counterparty', 'credit', 'maturity'].forEach((action) =>
      this.props.actions[action].updateSelectedDate(date),
    );
  };

  validateCapitalBasis(previousProps, nextProps) {
    const hasMissingCapitalBasisError =
      nextProps.holding.error &&
      nextProps.holding.error.data &&
      nextProps.holding.error.data.error_message === 'missing-capital-basis-data';

    if (hasMissingCapitalBasisError && !nextProps.holding.isFetching) {
      previousProps.actions.session.pushNotification('invalidCapitalBasisQueryDate', 'danger');
      this.onDateChange(formatToShortDate(today()));
    }
  }

  changeView = (tab) => {
    this.props.navigate(`${baseRoute}/${this.state.currentTab}/${tab}`);
  };

  fetchPortfolioHeaderData(date) {
    const params = date ? { date } : {};

    this.fetchTradesSummaryIfNeeded(params);
    this.fetchTradesMaturingIfNeeded(params);
    this.fetchQuotesIfNeeded();
  }

  fetchUnsettledTrades() {
    this.props.actions.listUnsettledTradesAction();
  }

  updateSelectedDate(date) {
    if (!date || !isValidDate(date)) {
      return;
    }
    this.props.actions.portfolio.updateSelectedDate(date);
  }

  fetchProactiveDataIfNeeded(previousProps, nextProps) {
    const children = ['holding', 'counterparty', 'credit', 'maturity', 'allocation', 'assets'];
    const fetchIsDone = children.every((child) => {
      if (previousProps[child].isFetching && !previousProps[child][child] && !nextProps[child].isFetching) {
        this.fetchProactiveData();

        return false;
      }

      return true;
    });

    if (fetchIsDone && previousProps.portfolio.refresh) {
      this.fetchProactiveData();
      previousProps.actions.portfolio.refreshPortfolio({ refresh: false });
    }
  }

  fetchProactiveData() {
    const {
      portfolio: { filter: portfolioFilter },
    } = this.props;

    const refreshActions = {
      holding: (actions, params) => this.fetchHoldingData(params),
      counterparty: (actions, params) => actions.counterparty.fetchCounterpartyIfNeeded(params),
      maturity: (actions, params) => actions.maturity.fetchMaturityIfNeeded(params),
      credit: (actions, params) => actions.credit.fetchCreditIfNeeded(params),
    };

    const getParams = (container) => ({
      ...portfolioFilter,
      ...this.props[container].filter,
    });

    Object.keys(refreshActions).forEach((container) => {
      const params = getParams(container);
      refreshActions[container](this.props.actions, params);
    });
  }

  fetchHoldingData(holdingParams) {
    const { actions } = this.props;

    actions.holding.fetchHoldingIfNeeded(holdingParams);
    actions.holding.fetchTradesSummaryFilterIfNeeded(holdingParams);
  }

  fetchTradesSummaryIfNeeded(params) {
    this.props.actions.holding.fetchTradesSummaryIfNeeded(params);
  }

  fetchTradesMaturingIfNeeded() {
    this.props.actions.holding.fetchTradesMaturingIfNeeded();
  }

  fetchQuotesIfNeeded() {
    this.props.actions.quotes.fetchRfqIfNeeded();
  }

  renderLoader = () => <Loadable isLoading />;

  isHoldingFetching = () => {
    const { holding, unsettled, allocation, assets } = this.props;

    return holding.isFetching || unsettled.isFetching || allocation.isFetching || assets.isFetching;
  };

  isRfqFetching = () => {
    const { quotes, maturing } = this.props;

    return quotes.isFetching || maturing.isFetching;
  };

  isComplianceFetching = () => {
    const { counterparty, credit, maturity } = this.props;

    return counterparty.isFetching || credit.isFetching || maturity.isFetching;
  };

  isComplianceFetched = () => {
    const {
      counterparty: { counterparty },
      credit: { credit },
      maturity: { maturity },
    } = this.props;

    return !!(counterparty && credit && maturity);
  };

  isCompliant = () => {
    const {
      counterparty: { counterparty },
      credit: { credit },
      maturity: { maturity },
    } = this.props;

    if (!counterparty || !credit || !maturity) return false;

    return counterparty.isCompliant && credit.isCompliant && maturity.isCompliant;
  };

  hasRfqsAction = () => {
    const { maturing } = this.props.holding;
    const quotesRfqs = this.props.quotes.rfqs || [];

    const openedRfqs = quotesRfqs.filter(({ rfqStatus, rfqConfirmed }) => isRfqOpened(rfqStatus, rfqConfirmed));
    const quotesReceivedCount = openedRfqs.reduce((acc, rfq) => acc + rfq.quotesReceived, 0);

    return maturing.sum > 0 || quotesReceivedCount > 0;
  };

  buildTab = ({ tabLabelId, badge }) => (
    <React.Fragment>
      <FormattedMessage tagName="span" id={tabLabelId} />
      {badge}
    </React.Fragment>
  );

  buildTabs = () => [
    {
      key: holdingsTabKey,
      href: holdingsTabKey,
      isLoading: this.isHoldingFetching(),
      component: this.buildTab({ tabLabelId: holdingsTabKey }),
    },
    {
      key: rfqsTabKey,
      href: rfqsTabKey,
      isLoading: this.isRfqFetching(),
      component: <RfqTabBadge />,
    },
    {
      key: compliance,
      href: compliance,
      isLoading: this.isComplianceFetching(),
      component: this.buildTab({
        tabLabelId: compliance,
        badge: this.isComplianceFetched() && (
          <span
            className={classNames('circle-alert', {
              compliant: this.isCompliant(),
            })}
          />
        ),
      }),
    },
    {
      key: reports,
      href: reports,
      component: this.buildTab({ tabLabelId: reports }),
    },
    {
      key: sandbox,
      href: sandbox,
      component: this.buildTab({ tabLabelId: sandbox }),
      user: this.props.session.user,
      licence: licences.sandbox,
      requiredTo: roles.finance,
    },
  ];

  updateLocationQueryIfNeeded = (tab, secondaryTab) => {
    if (tab === holdingsTabKey && secondaryTab === current) {
      this.props.actions.session.updateLocationQuery(this.props.holding.filter);
    } else if (tab === compliance) {
      this.props[secondaryTab] && this.props.actions.session.updateLocationQuery(this.props[secondaryTab].filter);
    }
  };

  setCurrentSecondaryTab = (primaryTab, secondaryTab) => {
    this.setState((prevState) => ({
      currentSecondaryTab: {
        ...prevState.currentSecondaryTab,
        ...{ [primaryTab]: secondaryTab },
      },
    }));
  };

  updateLocation = (primaryTab) => {
    const { navigate } = this.props;
    this.setState({ currentTab: primaryTab });
    const secondaryTab = this.state.currentSecondaryTab[primaryTab];

    navigate(`${baseRoute}/${primaryTab}/${secondaryTab || ''}`);

    this.updateLocationQueryIfNeeded(primaryTab, secondaryTab);
  };

  onStartTour = () => this.tourChild.current.onReset();

  onFeedbackSubmit = async (message) => {
    this.setState({ isLoadingFeedback: true });

    try {
      await sendFeedbackEmail(message);
      showToastMessage(this.props.intl.formatMessage({ id: 'feedbackSentSuccessfully' }), MessageType.SUCCESS);
      this.setState({ isFeedbackDialogOpen: false, isLoadingFeedback: false });
    } catch (e) {
      showResponseErrorMessage({ intl: this.props.intl, error: e });
      this.setState({ isLoadingFeedback: false });
    }
  };

  openFeedbackDialog = () => this.setState({ isFeedbackDialogOpen: true });

  closeFeedbackDialog = () => this.setState({ isFeedbackDialogOpen: false });

  render() {
    if (!userStore.hasToken()) {
      return <div className="home main-loader">{this.renderLoader()}</div>;
    }

    return (
      <section className="home-container">
        <MainTabs activeKey={this.state.currentTab} fixed>
          <Row contentBetween alignItemsCenter className="tab-container-header" data-tour={tourElements.primaryTabs}>
            <PrimaryTabs items={this.buildTabs()} onSelect={this.updateLocation} />

            <div className="actions">
              <InformationDropdown
                options={[
                  {
                    label: this.props.intl.formatMessage({
                      id: 'sendFeedbackEmail',
                    }),
                    onClick: () => this.openFeedbackDialog(),
                  },
                  {
                    label: this.props.intl.formatMessage({
                      id: 'replayGuide',
                    }),
                    onClick: this.onStartTour,
                  },
                ]}
              />
            </div>
          </Row>

          <Column className="home">
            <Tab.Content animation>
              <Tab.Pane key="portfolio-pane" eventKey={holdingsTabKey} unmountOnExit mountOnEnter>
                <HoldingsTab
                  updatePrimaryTab={this.updateLocation}
                  onSecondaryTabChange={(secondaryTab) => this.setCurrentSecondaryTab(holdingsTabKey, secondaryTab)}
                />
              </Tab.Pane>
              <Tab.Pane key="rfqs-pane" eventKey={rfqsTabKey} mountOnEnter>
                <RfqsTab
                  onSecondaryTabChange={(secondaryTab) => this.setCurrentSecondaryTab(rfqsTabKey, secondaryTab)}
                />
              </Tab.Pane>
              <Tab.Pane key="compliance-pane" eventKey={compliance} unmountOnExit mountOnEnter>
                <ComplianceTab
                  onSecondaryTabChange={(secondaryTab) => this.setCurrentSecondaryTab(compliance, secondaryTab)}
                />
              </Tab.Pane>
              <Tab.Pane key="reports-pane" eventKey={reports} mountOnEnter>
                <ReportsTab
                  onSecondaryTabChange={(secondaryTab) => this.setCurrentSecondaryTab(reports, secondaryTab)}
                />
              </Tab.Pane>
              <Tab.Pane key="sandbox-pane" eventKey={sandbox} mountOnEnter>
                <Protect
                  user={this.props.session.user}
                  requiredTo={roles.finance}
                  licence={licences.sandbox}
                  errorComponent={<Forbidden />}
                >
                  <SandboxTab
                    onSecondaryTabChange={(secondaryTab) => this.setCurrentSecondaryTab(sandbox, secondaryTab)}
                  />
                </Protect>
              </Tab.Pane>
            </Tab.Content>
          </Column>
        </MainTabs>
        <Tour name="portfolio" ref={this.tourChild} changeView={this.updateLocation} />
        <FeedbackDialog
          show={this.state.isFeedbackDialogOpen}
          isLoading={this.state.isLoadingFeedback}
          onConfirm={this.onFeedbackSubmit}
          onCancel={this.closeFeedbackDialog}
        />
      </section>
    );
  }
}

HomePage.propTypes = {
  actions: PropTypes.shape().isRequired,
  params: PropTypes.shape().isRequired,
  holding: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  unsettled: PropTypes.shape().isRequired,
  allocation: PropTypes.shape().isRequired,
  assets: PropTypes.shape().isRequired,
  counterparty: PropTypes.shape().isRequired,
  on: PropTypes.func,
  credit: PropTypes.shape().isRequired,
  maturity: PropTypes.shape().isRequired,
  portfolio: PropTypes.shape().isRequired,
  quotes: PropTypes.shape().isRequired,
  maturing: PropTypes.shape().isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  includeSocket({ rooms: [rooms.rfq] }),
  injectIntl,
  withLocation,
  withParams,
  withNavigate,
  withSearchParams,
)(HomePage);
