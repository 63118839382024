import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { NumericFormat } from 'react-number-format';

import { normalizeCurrency } from '../../parse-float';

const CurrencyInput = ({ placeholder, intl, ...props }) => {
  const { value, onBlur } = props;
  const normalizedValue = value ? normalizeCurrency(value) : '';
  const intlPlaceholder = placeholder && intl.formatMessage({ id: placeholder }, { ...props });

  return (
    <NumericFormat
      className="form-control"
      thousandSeparator=","
      decimalSeparator="."
      value={normalizedValue}
      placeholder={intlPlaceholder}
      onBlur={(e) => onBlur && onBlur(e)}
      {...props}
    />
  );
};

export const FormikCurrencyInput = ({ field, ...props }) => <CurrencyInput {...field} {...props} />;

FormikCurrencyInput.propTypes = {
  field: PropTypes.shape().isRequired,
};

CurrencyInput.defaultProps = {
  value: 0,
};

CurrencyInput.propTypes = {
  allowNegative: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default injectIntl(CurrencyInput);
