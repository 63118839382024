import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Sound from 'react-sound';
import { bindActionCreators, compose } from 'redux';

import { fetchQuotes } from '../../actions/reports/outgoing-rfqs/rfqs-history';
import { hasDefaultTPlusNDaysSelector } from '../../actions/session-selector';
import rooms from '../../api/socket/rooms';
import IncomingQuotesDialog from '../../components/dashboard/incoming-quotes-dialog';
import OutgoingQuotesDialog from '../../components/dashboard/outgoing-quotes-dialog';
import { UnsolicitedRatesheetsSnapshot } from '../../components/dashboard/unsolicited-ratesheets-snapshot/UnsolicitedRatesheetsSnapshot';
import includeSocket from '../../components/hoc/include-socket';
import {
  fetchAnonymisedDashboard as fetchAnonymisedDashboardAction,
  fetchDashboard as fetchDashboardAction,
  fetchIncomingDashboard as fetchIncomingDashboardAction,
  fetchOutgoingDashboard as fetchOutgoingDashboardAction,
} from '../../ducks/dashboard';
import { getIncomingPanelData, getOutgoingPanelData } from '../../ducks/dashboard/selectors';
import { BuySellBondRfqsSendQuotesDrawer } from '../rfqs/buy-sell-bond-rfqs/BuySellBondRfqsSendQuotesDrawer';
import { MessageType, showToastMessage } from '../toast/toast';
import AdiTradesPanel from './adi-trades-panel';
import IncomingPanel from './incoming-panel';
import OutgoingPanel from './outgoing-panel';

const mapStateToProps = (state) => ({
  holding: state.holding,
  dashboard: state.dashboard,
  licences: state.session.user.licences,
  outgoingQuotes: state.outgoingRfqsReport.quotes,
  currency: state.session.user.currency,
  hasDefaultTPlusNDays: hasDefaultTPlusNDaysSelector(state),
  incoming: getIncomingPanelData(state),
  outgoing: getOutgoingPanelData(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchOutgoingQuotes: bindActionCreators(fetchQuotes, dispatch),
    fetchDashboard: bindActionCreators(fetchDashboardAction, dispatch),
    fetchIncomingDashboard: bindActionCreators(fetchIncomingDashboardAction, dispatch),
    fetchOutgoingDashboard: bindActionCreators(fetchOutgoingDashboardAction, dispatch),
    fetchAnonymisedDashboard: bindActionCreators(fetchAnonymisedDashboardAction, dispatch),
  },
});

export class RfqsDashboardPanelComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showIncomingQuotesDialog: false,
      showOutgoingQuotesDialog: false,
      adiSecurityRfq: null,
      rfqQuotes: {},
      soundPlayStatus: Sound.status.STOPPED,
      unsolicitedRatesheetTrade: null,
    };
  }

  componentDidMount() {
    this.props.actions.fetchDashboard();

    this.props.on('server:rfq-updated', () => {
      this.props.actions.fetchDashboard();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.notifyPendingAction(nextProps);
  }

  notifyPendingAction({ dashboard: nextDashboard }) {
    const { dashboard: currentDashboard } = this.props;

    const incomingPending = this.hasReceivedPending(currentDashboard.incomingRfqs, nextDashboard.incomingRfqs);
    const outgoingPending = this.hasReceivedPending(currentDashboard.outgoingRfqs, nextDashboard.outgoingRfqs);

    if (incomingPending || outgoingPending) {
      let notificationMessage;

      if (incomingPending && outgoingPending) {
        notificationMessage = 'dashboard.notifications.incomingAndOutgoingPending';
      } else if (incomingPending) {
        notificationMessage = 'dashboard.notifications.incomingPending';
      } else if (outgoingPending) {
        notificationMessage = 'dashboard.notifications.outgoingPending';
      }

      this.setState({
        soundPlayStatus: Sound.status.PLAYING,
      });

      showToastMessage(this.props.intl.formatMessage({ id: notificationMessage }), MessageType.INFO);
    }
  }

  hasReceivedPending = (curr, next) => {
    const currentPending = curr.totalPending || 0;
    const nextPending = next.totalPending || 0;

    return nextPending > currentPending;
  };

  toggleIncomingQuotesDialog = async (uuid) => {
    await this.props.actions.fetchAnonymisedDashboard(uuid);

    this.setState({
      showIncomingQuotesDialog: true,
      rfqQuotes: this.props.dashboard.anonymised.rfqQuotes,
    });
  };

  toggleOutgoingQuotesDialog = async (uuid) => {
    await this.props.actions.fetchOutgoingQuotes(uuid);

    this.setState({
      showOutgoingQuotesDialog: true,
      rfqQuotes: this.props.outgoingQuotes,
    });
  };

  showSecuritiesRfqDrawer = ({ uuid, operation }) => {
    this.setState({ adiSecurityRfq: { uuid, operation } });
  };

  toggleUnsolicitedRatesheetsSnapshotDialog = ({ counterpartyId, sourceEntityId }) => {
    this.setState({ unsolicitedRatesheetTrade: { counterpartyId, sourceEntityId } });
  };

  onCloseRfqQuotesDialog() {
    this.setState({
      showIncomingQuotesDialog: false,
      showOutgoingQuotesDialog: false,
      rfqQuotes: {},
    });
  }

  render() {
    const {
      dashboard: { adiTrades, initialized },
      currency,
      incoming,
      outgoing,
    } = this.props;

    const {
      showIncomingQuotesDialog,
      showOutgoingQuotesDialog,
      rfqQuotes,
      soundPlayStatus,
      unsolicitedRatesheetTrade,
    } = this.state;

    return (
      <React.Fragment>
        <div className="dashboard-items">
          <IncomingPanel
            data={incoming}
            toggleRfqQuotesDialog={this.toggleIncomingQuotesDialog}
            initialized={initialized}
            onQuoteUpdatedSuccess={this.props.actions.fetchDashboard}
            showSettlementDate
          />

          <OutgoingPanel
            data={outgoing}
            toggleRfqQuotesDialog={this.toggleOutgoingQuotesDialog}
            initialized={initialized}
            currency={currency}
            showSettlementDate
            onCloseSellBondRfqSuccess={this.props.actions.fetchOutgoingDashboard}
          />

          <AdiTradesPanel
            adiTrades={adiTrades}
            toggleRfqQuotesDialog={this.toggleIncomingQuotesDialog}
            showSecuritiesRfqDrawer={this.showSecuritiesRfqDrawer}
            toggleUnsolicitedRatesheetsSnapshotDialog={this.toggleUnsolicitedRatesheetsSnapshotDialog}
            initialized={initialized}
            showSettlementDate
          />
        </div>
        <IncomingQuotesDialog
          onClose={() => this.onCloseRfqQuotesDialog()}
          show={showIncomingQuotesDialog}
          rfqQuotes={rfqQuotes}
        />
        <OutgoingQuotesDialog
          onClose={() => this.onCloseRfqQuotesDialog()}
          show={showOutgoingQuotesDialog}
          rfqQuotes={this.state.rfqQuotes}
        />
        <Sound
          url="https://s3-ap-southeast-2.amazonaws.com/imperium-public-files/imperium-chime.mp3"
          playStatus={soundPlayStatus}
          autoLoad
          onFinishedPlaying={() => this.setState({ soundPlayStatus: Sound.status.STOPPED })}
        />
        <BuySellBondRfqsSendQuotesDrawer
          rfq={this.state.adiSecurityRfq}
          onCloseDrawer={() => this.setState({ adiSecurityRfq: null })}
        />
        {unsolicitedRatesheetTrade && (
          <UnsolicitedRatesheetsSnapshot
            onClose={() => this.setState({ unsolicitedRatesheetTrade: null })}
            show={!!unsolicitedRatesheetTrade}
            sourceEntityId={unsolicitedRatesheetTrade.sourceEntityId}
            investorTenantId={unsolicitedRatesheetTrade.counterpartyId}
          />
        )}
      </React.Fragment>
    );
  }
}

RfqsDashboardPanelComponent.propTypes = {
  actions: PropTypes.shape().isRequired,
  dashboard: PropTypes.shape().isRequired,
  incoming: PropTypes.shape().isRequired,
  outgoing: PropTypes.shape().isRequired,
  currency: PropTypes.string,
  initialized: PropTypes.bool,
  outgoingQuotes: PropTypes.shape(),
  on: PropTypes.func,
};

export const RfqsDashboardPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  includeSocket({ rooms: [rooms.rfq] }),
  injectIntl,
)(RfqsDashboardPanelComponent);
