import './records.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Checkbox from './checkbox';

const Records = ({
  actions,
  ariaLabel,
  columns,
  presenter,
  onSortColumnOrder,
  onSelectAllChange,
  highlightedColumn,
  data,
  headerPresenter = renderHeaders,
  options,
}) => (
  <div className="records">
    <Table aria-label={ariaLabel} responsive striped hover>
      <thead>
        <tr>{headerPresenter(columns, onSortColumnOrder, highlightedColumn, data, onSelectAllChange)}</tr>
      </thead>
      <tbody>{renderLines({ actions, data, presenter, options })}</tbody>
    </Table>
  </div>
);

Records.propTypes = {
  actions: PropTypes.object,
  columns: PropTypes.object,
  data: PropTypes.array,
  ariaLabel: PropTypes.string,
  headerPresenter: PropTypes.func,
  highlightedColumn: PropTypes.string,
  onSortColumnOrder: PropTypes.func,
  presenter: PropTypes.func,
  options: PropTypes.object,
};

export const renderHeaders = (columns, onSortColumnOrder, highlightedColumn, data, onSelectAllChange) =>
  Object.keys(columns).map((key, index) => {
    const headerAttributes = { ...columns[key] };

    if (headerAttributes?.selectAll) {
      return (
        <th key={`${index}-${key}`}>
          <Checkbox
            name={`select-all-${index}-${key}`}
            onChange={onSelectAllChange}
            checked={headerAttributes.isSelectAllChecked}
          />
        </th>
      );
    }

    let sortColumn;
    const hasSort = headerAttributes && headerAttributes.sort;
    const isColumnSelected =
      highlightedColumn && hasSort && new RegExp(`^-?${headerAttributes.sort}$`).test(highlightedColumn);

    if (hasSort) {
      sortColumn = () => onSortColumnOrder(headerAttributes);
    }

    return (
      <th key={`${index}-${key}`} className={classNames(columns[key].className)}>
        <span
          className={classNames(
            {
              'column-link': hasSort,
            },
            {
              selected: isColumnSelected,
            },
          )}
          onClick={sortColumn}
        >
          {hasSort && renderSortIcon(isColumnSelected, highlightedColumn)}
          {columns[key].label && <FormattedMessage id={columns[key].label} values={columns[key].values} />}
        </span>
      </th>
    );
  });

const renderSortIcon = (isActive, checker) => (
  <i
    className={classNames('glyphicon', {
      'glyphicon-menu-up': isActive && !checker?.includes('-'),
      'glyphicon-menu-down': isActive && checker?.includes('-'),
      'glyphicon-sort': !isActive,
      'sort-icon': true,
      'sort-icon-inactive': !isActive,
    })}
  />
);

const renderLines = ({ actions, data, presenter, options }) =>
  data && data.map((item, index) => presenter(item, index, actions, options));

export default Records;
