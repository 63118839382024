import { createSelector } from 'reselect';

import { TD } from '../../api/holding/instrument-codes';
import { sameDayAsToday } from '../../date';

const getRatesheet = ({ ratesheets }) => ({ ...ratesheets?.data, isFetching: ratesheets?.isFetching });

const getRatesheetByInstrumentCode = (byInstrumentCode) =>
  createSelector(
    getRatesheet,
    (data) => data && data.list.find(({ instrumentCode }) => instrumentCode === byInstrumentCode),
  );

export const getTermDepositRatesheet = createSelector(getRatesheetByInstrumentCode(TD), (ratesheetByInstrumentCode) =>
  ratesheetByInstrumentCode
    ? ratesheetByInstrumentCode.ratesheet.map((ratesheet) => ({
        available: ratesheet.available,
        updatedAt: ratesheet.updatedAt,
        updatedBy: ratesheet.updatedBy,
        segmentCode: ratesheet.segmentCode,
        rates: Object.keys(ratesheet.rates).map((tenor) => ({
          tenor,
          rate: ratesheet.rates[tenor] && +ratesheet.rates[tenor],
        })),
      }))
    : [],
);

export const getBankRatesheet = createSelector(getRatesheet, ({ list: bankRatesheet, isFetching }) =>
  bankRatesheet
    ? bankRatesheet.reduce(
        (bankRatesheetByInstrumentCode, { instrumentCode, ratesheet }) => ({
          ...bankRatesheetByInstrumentCode,
          [instrumentCode]: ratesheet.map(({ updatedAt, updatedBy, segmentCode, rates, available }) => ({
            available,
            isFetching,
            updatedAt,
            updatedBy,
            segmentCode,
            rates: Object.keys(rates).map((tenor) => ({
              tenor,
              rate: rates[tenor] && +rates[tenor],
            })),
          })),
        }),
        {},
      )
    : {},
);

export const defaultInstrumentCode = 'TD';
const defaultSegmentCode = 'LOCALGOV';

export const getAvailableRatesheets = (ratesheets) => {
  const ratesheetsWithInstrumentCode = Object.keys(ratesheets).reduce((ratesheetsBy, instrumentCode) => {
    ratesheetsBy[instrumentCode] = ratesheets[instrumentCode].map((ratesheet) => ({ ...ratesheet, instrumentCode }));

    return ratesheetsBy;
  }, {});

  const availableRatesheets = Object.keys(ratesheetsWithInstrumentCode)
    .flatMap((instrumentCode) => ratesheetsWithInstrumentCode[instrumentCode])
    .filter(({ available }) => available);

  return availableRatesheets;
};

export const getRatesheetByInstrumentAndSegmentCode = ({
  ratesheets = {},
  instrumentCode = defaultInstrumentCode,
  segmentCode = defaultSegmentCode,
}) => {
  let ratesheetByInstrumentCode = instrumentCode && ratesheets[instrumentCode];

  if (!ratesheetByInstrumentCode) {
    const availableRatesheets = getAvailableRatesheets(ratesheets);

    const [tdPreferableRatesheet] = availableRatesheets.sort((firstRatesheet, secondRatesheet) =>
      firstRatesheet.instrumentCode < secondRatesheet.instrumentCode ? 1 : -1,
    );

    return tdPreferableRatesheet;
  }

  if (!ratesheetByInstrumentCode) {
    return null;
  }

  let ratesheetBySegmentCode = ratesheetByInstrumentCode.find(
    ({ available, segmentCode: ratesheetSegmentCode }) => ratesheetSegmentCode === segmentCode && available,
  );

  return ratesheetBySegmentCode;
};

export const getTdRatesheetLastUpdatedAt = createSelector(getBankRatesheet, (ratesheets) => {
  const ratesheetBySegmentCode = getRatesheetByInstrumentAndSegmentCode({ ratesheets, instrumentCode: 'TD' });

  return ratesheetBySegmentCode && ratesheetBySegmentCode.updatedAt;
});

export const isTdRatesheetStale = createSelector(
  getTdRatesheetLastUpdatedAt,
  (latestTermDepositRatesheetUpdatedAt) => !sameDayAsToday(latestTermDepositRatesheetUpdatedAt),
);
