import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import { bool, func, number, shape, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedNumber } from 'react-intl';

import { couponFormat, emptyCharacter } from '../../../../../../format-numbers';
import { CreateTradeFromRatePopover } from './CreateTradeFromRatePopover';

export const initialFormValues = {
  principal: '',
};

export const CreateTradeFromRatePopoverButton = ({
  tenor,
  rate,
  issuer,
  onCreateRfqClick,
  onReinvestRfqClick,
  isAccepting,
  onSubmit,
  intl,
  maturingSummary,
  unsolicitedRatesheetMaxBankAmount,
  unsolicitedRatesheetCustomMaturityDateRangeLimit,
  ratesheet,
}) => {
  const [show, setShow] = useState(false);

  const rateButton = useRef();

  const isValidRate = rate !== null;

  return (
    <React.Fragment>
      <Button
        className={classNames({ 'btn-solid-primary': show })}
        disabled={isAccepting || !isValidRate}
        onClick={() => setShow(() => !show)}
        ref={rateButton}
      >
        {isValidRate ? <FormattedNumber {...couponFormat} value={rate} /> : emptyCharacter}
      </Button>
      {show && (
        <CreateTradeFromRatePopover
          ratesheet={ratesheet}
          isAccepting={isAccepting}
          issuer={issuer}
          onSubmit={onSubmit}
          onCreateRfqClick={onCreateRfqClick}
          onReinvestRfqClick={onReinvestRfqClick}
          tenor={tenor}
          rate={rate}
          maturingSummary={maturingSummary}
          show={show}
          setShow={setShow}
          rateButton={rateButton}
          unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
          unsolicitedRatesheetCustomMaturityDateRangeLimit={unsolicitedRatesheetCustomMaturityDateRangeLimit}
        />
      )}
    </React.Fragment>
  );
};

CreateTradeFromRatePopoverButton.defaultProps = {
  isAccepting: false,
};

CreateTradeFromRatePopoverButton.propTypes = {
  onConfirm: func,
  onCreateRfqClick: func,
  onReinvestRfqClick: func,
  tenor: string,
  isAccepting: bool,
  rate: number,
  unsolicitedRatesheetMaxBankAmount: number,
  issuer: shape(),
  maturingSummary: shape(),
  intl: shape(),
  ratesheet: shape(),
};
