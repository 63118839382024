import './summary-item.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Column } from '../Flex/Flex';

export const SummaryItem = ({ top, middle, bottom, isLoading, highlight, className, onClick }) => (
  <Column
    className={classNames('summary-content', className, { highlight, 'is-loading': isLoading })}
    data-testid="summary-item"
    contentCenter
    onClick={onClick}
  >
    <React.Fragment>
      <p>{top}</p>
      <h4 data-testid="summary-value">{middle}</h4>
      <div className="bottom-content">{bottom}</div>
    </React.Fragment>
  </Column>
);

SummaryItem.propTypes = {
  top: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  middle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  isLoading: PropTypes.bool,
  highlight: PropTypes.bool,
  className: PropTypes.string,
};

SummaryItem.defaultProps = {
  isLoading: false,
  highlight: false,
};
