import './pulse-blob.scss';

import React from 'react';

import PropTypes from 'prop-types';

export const PulseBlob = React.forwardRef(({ size, ...props }, ref) => (
  <div ref={ref} className={`blob blob-${size}`} {...props} />
));

PulseBlob.propTypes = {
  size: PropTypes.string,
};

PulseBlob.defaultProps = {
  size: 'sm',
};
