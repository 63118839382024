import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { bool, func, number, shape, string } from 'prop-types';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import useSWR from 'swr';

import { hasSeparationOfDuties } from '../../../../../../actions/session-selector';
import { initialGeneralAllocationCode } from '../../../../../../api/holding/codes';
import { getTradesSummary, getTradesSummaryUrl } from '../../../../../../api/holding/summary';
import { DatePicker } from '../../../../../../components/common';
import { buildDatepickerOptionsWithLimitedDays } from '../../../../../../components/common/datepicker-options';
import { formatToShortDate, getBusinessDate, getDateInTimeFrameFrom, isSameDay, today } from '../../../../../../date';
import { splitTenor } from '../../../../../Tenors/TenorItem';
import { CollationOfRatesheetsReinvestmentForm } from './CollationOfRatesheetsReinvestmentForm';
import { CreateTradeFromRatePopoverForm } from './CreateTradeFromRateForm';

export const initialFormValues = {
  principal: '',
};

const steps = {
  chooseYourDestiny: 'chooseYourDestiny',
  newFund: 'newFund',
  reinvestment: 'reinvestment',
};

const initialGeneralAllocationCodes = [initialGeneralAllocationCode];

export const CreateTradeFromRatePopoverComponent = injectIntl(
  ({
    children,
    rateButton,
    show,
    setShow,
    tenor,
    rate,
    issuer,
    onCreateRfqClick,
    onReinvestRfqClick,
    isAccepting,
    onSubmit,
    intl,
    maturingSummary,
    unsolicitedRatesheetMaxBankAmount,
    unsolicitedRatesheetCustomMaturityDateRangeLimit,
    hasSeparationOfDuties,
    ratesheet,
  }) => {
    const [step, setStep] = useState(
      maturingSummary?.maturingTodayNotDealtWith ? steps.chooseYourDestiny : steps.newFund,
    );

    const { data: summary } = useSWR(getTradesSummaryUrl, getTradesSummary);
    const popoverOverlayContainer = useRef();

    const { time, unit } = splitTenor(tenor);

    const [allocationCodes, setAllocationCodes] = useState(initialGeneralAllocationCodes);

    useEffect(() => {
      if (!summary?.allocationCodes?.length) {
        return;
      }

      setAllocationCodes(
        summary?.allocationCodes.map((allocation) => ({
          value: allocation.code,
          label: allocation.code,
        })),
      );
    }, [summary]);

    const onCreateTradeSubmit = async (values) => {
      await onSubmit(
        Object.assign(
          values,
          isDifferentThanMaturityDateFromTenor && { customMaturityDate: formatToShortDate(customMaturityDate) },
        ),
      );

      setShow(false);
    };

    useEffect(() => {
      setStep(maturingSummary?.maturingTodayNotDealtWith ? steps.chooseYourDestiny : steps.newFund);
    }, [maturingSummary]);

    const tenorMaturityDate = getBusinessDate({
      date: getDateInTimeFrameFrom(today(), time, unit),
    }).toDate();

    const [customMaturityDate, setCustomMaturityDate] = useState(null);

    const isDifferentThanMaturityDateFromTenor =
      customMaturityDate && !isSameDay(customMaturityDate, tenorMaturityDate);

    return (
      <React.Fragment>
        <Overlay
          trigger="click"
          placement="bottom"
          rootClose
          show={show}
          ref={popoverOverlayContainer}
          onHide={() => !isAccepting && setShow(false)}
          target={rateButton?.current}
        >
          <Popover
            className={classNames('create-trade-from-rate-popover light', { 'is-accepting': isAccepting })}
            id={tenor}
            title={
              <Column alignItemsStart>
                <FormattedMessage tagName="h4" id={`offerTenor${unit}`} values={{ count: time }} />
                <p>Bank: {issuer.name}</p>
              </Column>
            }
          >
            {step === steps.chooseYourDestiny && (
              <Column className="create-trade-from-rate-popover-reinvestment" alignItemsCenter justifyContentCenter>
                <Row>
                  <Column alignItemsCenter className="create-trade-from-rate-popover-reinvestment-box">
                    <h2>{maturingSummary.maturingTodayCount}</h2>
                    <FormattedMessage tagName="span" id="maturingToday" />
                  </Column>
                  <Column alignItemsCenter className="create-trade-from-rate-popover-reinvestment-box">
                    <h2>{maturingSummary.maturingTodayNotDealtWith}</h2>
                    <FormattedMessage tagName="span" id="maturitiesNotDealt" />
                  </Column>
                </Row>
                <FormattedMessage
                  id="unsolicitedRatesheetHasMaturitiesNotDealtWith"
                  tagName="p"
                  values={{ maturingTodayNotDealtWith: maturingSummary.maturingTodayNotDealtWith }}
                />
                <Row className="actions" justifyContentEnd>
                  <Button className="btn-solid-primary" onClick={() => setStep(steps.reinvestment)}>
                    <FormattedMessage id="unsolicitedRatesheetReinvest" />
                  </Button>
                  <Button className="btn-solid-primary" onClick={() => setStep(steps.newFund)}>
                    <FormattedMessage id="unsolicitedRatesheetNewFunds" />
                  </Button>
                </Row>
              </Column>
            )}
            {step !== steps.chooseYourDestiny && (
              <Column className="create-trade-from-rate-popover-form-header">
                <Row alignItemsStart>
                  <FormattedMessage
                    tagName="h3"
                    id="rate"
                    values={{ rate: rate.toFixed(2), b: (msg) => <b>{msg}</b> }}
                  />
                  <DatePicker
                    ariaLabelledBy="maturity date"
                    disableWeekends
                    disableHolidays
                    labelId="maturity"
                    onChange={(selectedDate) => setCustomMaturityDate(selectedDate)}
                    value={customMaturityDate || tenorMaturityDate}
                    wrapperClassName={classNames({ 'is-custom': isDifferentThanMaturityDateFromTenor })}
                    {...buildDatepickerOptionsWithLimitedDays({
                      date: tenorMaturityDate,
                      rangeLimit: unsolicitedRatesheetCustomMaturityDateRangeLimit,
                    })}
                  />
                </Row>
                {hasSeparationOfDuties && (
                  <Row>
                    <FormattedMessage tagName="h3" id="unsolicitedRatesheetSeparationOfDuties" />
                  </Row>
                )}
              </Column>
            )}
            {step === steps.newFund && (
              <CreateTradeFromRatePopoverForm
                allocationCodes={allocationCodes}
                tenor={tenor}
                rate={rate}
                issuer={issuer}
                onCancelClick={() => setShow(false)}
                onCreateTradeSubmit={onCreateTradeSubmit}
                popoverContainer={popoverOverlayContainer}
                onCreateRfqClick={(values) => {
                  onCreateRfqClick(values);
                  setShow(false);
                }}
                onAddAllocationCode={setAllocationCodes}
                unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
                hasSeparationOfDuties={hasSeparationOfDuties}
                ratesheet={ratesheet}
              />
            )}
            {step === steps.reinvestment && (
              <CollationOfRatesheetsReinvestmentForm
                allocationCodes={allocationCodes}
                tenor={tenor}
                rate={rate}
                issuer={issuer}
                onCancelClick={() => setShow(false)}
                onCreateTradeSubmit={onCreateTradeSubmit}
                popoverContainer={popoverOverlayContainer}
                onReinvestRfqClick={(values) => {
                  onReinvestRfqClick(values);
                  setShow(false);
                }}
                onAddAllocationCode={setAllocationCodes}
                unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
                hasSeparationOfDuties={hasSeparationOfDuties}
                ratesheet={ratesheet}
              />
            )}
          </Popover>
        </Overlay>
      </React.Fragment>
    );
  },
);

CreateTradeFromRatePopoverComponent.defaultProps = {
  isAccepting: false,
};

CreateTradeFromRatePopoverComponent.propTypes = {
  onConfirm: func,
  onCreateRfqClick: func,
  onReinvestRfqClick: func,
  tenor: string,
  rateButton: shape(),
  isAccepting: bool,
  rate: number,
  unsolicitedRatesheetMaxBankAmount: number,
  issuer: shape(),
  maturingSummary: shape(),
  intl: shape(),
  ratesheet: shape(),
};

const mapStateToProps = (state) => ({
  hasSeparationOfDuties: hasSeparationOfDuties(state),
});

export const CreateTradeFromRatePopover = connect(mapStateToProps)(CreateTradeFromRatePopoverComponent);
