import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Column } from 'react-display-flex';

import { SimpleTenorItem } from './SimpleTenorItem';

export const splitTenor = (tenor) => {
  const [time, unit] = tenor.match(/\d+|\D+/g);

  return { time, unit };
};

const onColumnClick = ({ onClick, tenor, rate, ref }) => {
  if (!onClick) {
    return;
  }

  const { unit, time } = splitTenor(tenor);

  onClick({ unit, time, tenor, rate, ref });
};

export const TenorItem = ({
  className,
  children,
  rate,
  tenor,
  isCustom,
  onClick,
  tenorItemComponent: TenorItemComponent = SimpleTenorItem,
  ...tenorItemComponentProps
}) => {
  const ref = React.useRef();

  return (
    <Column
      role="button"
      data-testid={`tenor-item-${tenor}`}
      className={cx('tenor', className, {
        disabled: rate === null,
        'is-dirty': isCustom,
      })}
      onClick={() => onColumnClick({ onClick, tenor, rate, ref })}
    >
      {children || <TenorItemComponent tenor={tenor} rate={rate} ref={ref} {...tenorItemComponentProps} />}
    </Column>
  );
};

TenorItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isCustom: PropTypes.bool,
  tenor: PropTypes.string.isRequired,
  rate: PropTypes.number,
  onClick: PropTypes.func,
  tenorItemComponent: PropTypes.elementType,
};

TenorItem.defaultProps = {
  className: '',
};
