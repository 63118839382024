import './update-ratesheet-drawer.scss';

import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import HotTable from 'react-handsontable';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { ConfirmationPopover, DateWithTimeZone, Drawer } from '../../../../../components/common';
import { sameDayAsToday } from '../../../../../date';
import {
  getAvailableRatesheets,
  getRatesheetByInstrumentAndSegmentCode,
} from '../../../../../ducks/ratesheet/selectors';
import { track, updateRatesheetsOnSendQuotesDrawer } from '../../../../../event-tracker';
import { couponFormat, toNumberFormat } from '../../../../../format-numbers';

export const UpdateRatesheetDrawer = ({
  ratesheets,
  updateRatesheets,
  instrumentCode,
  segmentCode,
  onUpdateRatesheet,
  onClose,
  onSkip,
  isOpen,
}) => {
  const tableRef = useRef();
  const navigate = useNavigate();
  const [currentRatesheet, setCurrentRatesheet] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const ratesheet = getRatesheetByInstrumentAndSegmentCode({ ratesheets, instrumentCode, segmentCode });

    if (ratesheet) {
      setCurrentRatesheet(ratesheet);
      setDataTable(convertRatesheetToTableItems(ratesheet));
    }
  }, [ratesheets, instrumentCode, segmentCode]);

  const onSaveClick = async () => {
    track(updateRatesheetsOnSendQuotesDrawer);

    setIsLoading(true);

    const rates = tableRef.current.hotInstance.getData();

    const payload = buildUpdateRatesheetPayload(ratesheets, rates);

    await updateRatesheets(payload);

    setIsLoading(false);

    onUpdateRatesheet && onUpdateRatesheet();
  };

  return (
    <Drawer
      titleId="updateRatesheet"
      className="light"
      open={isOpen}
      shouldCloseOnClickOutside={false}
      onClose={onClose}
      width="30vw"
    >
      {currentRatesheet ? (
        <Column className="update-ratesheet-drawer">
          <Row className="ratesheet-header" justifyContentSpaceBetween>
            {instrumentCode && (
              <Column>
                <dt>Asset</dt>
                <dd>{instrumentCode}</dd>
              </Column>
            )}
            <Column>
              <dt>Last updated</dt>
              <dd>{currentRatesheet && <DateWithTimeZone value={currentRatesheet.updatedAt} />}</dd>
            </Column>
            <Column>
              <dt>Updated by</dt>
              <dd>{currentRatesheet && currentRatesheet.updatedBy}</dd>
            </Column>
          </Row>
          {currentRatesheet && !sameDayAsToday(currentRatesheet.updatedAt) && (
            <Row className="ratesheet-alert" alignItemsCenter>
              <FaExclamationCircle />
              <p>These rates are stale. You can update/save these rates for Auto-fill proposes</p>
            </Row>
          )}
          <p className="ratesheet-note">
            NOTE - Ability to `Copy & Paste` from excel/csv, whether your internal pricing sheet is in horizontal or
            vertical alignment.
          </p>
          {currentRatesheet && (
            <HotTable
              colHeaders={['Rate']}
              rowHeaders={currentRatesheet && currentRatesheet.rates.map((_, index) => rowHeaders[index].label)}
              data={dataTable}
              startRows={16}
              maxRows={currentRatesheet && currentRatesheet.rates.length}
              className="ratesheet-table"
              rowHeights={40}
              ref={tableRef}
              onBeforePaste={convertRowToColumnIfNeeded}
            />
          )}
          <ConfirmationPopover
            buttonLabelId="save"
            confirmationMessageId="areYouSure"
            className="btn-solid-primary"
            popoverClass="light"
            type="button"
            onConfirm={onSaveClick}
            isConfirmingFromOutside={isLoading}
          />
          {onSkip && (
            <Button onClick={onSkip} bsStyle="link">
              Skip for now
            </Button>
          )}
        </Column>
      ) : (
        <Column className="update-ratesheet-drawer">
          <h3>Unavailable Ratesheet</h3>
          <p>Please mark one of the ratesheets segment as available to allow it to be updated</p>
          <Button className="btn-solid-primary" onClick={() => navigate('/ratesheets/update')}>
            Update Ratesheet
          </Button>
        </Column>
      )}
    </Drawer>
  );
};

UpdateRatesheetDrawer.propTypes = {
  ratesheets: PropTypes.shape().isRequired,
  updateRatesheets: PropTypes.func.isRequired,
  onUpdateRatesheet: PropTypes.func,
  instrumentCode: PropTypes.string,
  segmentCode: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSkip: PropTypes.func,
  isOpen: PropTypes.bool,
};

const convertRatesheetToTableItems = (ratesheetData) =>
  ratesheetData.rates.map(({ rate }) => ({
    rate: rate === null ? '' : toNumberFormat({ value: rate, ...couponFormat }),
  }));

const convertRowToColumnIfNeeded = (values) => {
  if (values.length === 1) {
    const rowValues = values.pop();
    rowValues.forEach((value) => values.push([value]));
  }
};

const rowHeaders = [
  { tenor: '1M', label: '1 Month' },
  { tenor: '2M', label: '2 Months' },
  { tenor: '3M', label: '3 Months' },
  { tenor: '4M', label: '4 Months' },
  { tenor: '5M', label: '5 Months' },
  { tenor: '6M', label: '6 Months' },
  { tenor: '7M', label: '7 Months' },
  { tenor: '8M', label: '8 Months' },
  { tenor: '9M', label: '9 Months' },
  { tenor: '10M', label: '10 Months' },
  { tenor: '11M', label: '11 Months' },
  { tenor: '12M', label: '12 Months' },
  { tenor: '2Y', label: '2 Years' },
  { tenor: '3Y', label: '3 Years' },
  { tenor: '4Y', label: '4 Years' },
  { tenor: '5Y', label: '5 Years' },
];

const buildUpdateRatesheetPayload = (ratesheets, rates) => {
  const availableRatesheets = getAvailableRatesheets(ratesheets);

  return availableRatesheets.map((ratesheet) => ({
    ...ratesheet,
    rates: buildUpdatedRatesheetRates(ratesheet, rates),
  }));
};

const buildUpdatedRatesheetRates = (ratesheet, rates) =>
  rates.reduce((compiledRates, [updatedRate], index) => {
    const currentRate = ratesheet.rates[index];

    return currentRate
      ? { ...compiledRates, [currentRate.tenor]: updatedRate === '' ? null : +updatedRate }
      : compiledRates;
  }, {});
