import React from 'react';

import classNames from 'classnames';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedDate, FormattedMessage } from 'react-intl';

import Tooltip from '../../../../../../components/common/tooltip';
import { sameDayAsToday } from '../../../../../../date';
import toFloat from '../../../../../../parse-float';
import { CreateTradeFromRatePopoverButton } from './CreateTradeFromRatePopoverButton';

export const columns = ({ currencySymbol, intl }) => ({
  customer: { label: 'adi', sort: 'issuerName' },
  ratings: {},
  updatedAt: {},
  '1M': { label: '1M', className: 'text-center', sort: '1M' },
  '2M': { label: '2M', className: 'text-center', sort: '2M' },
  '3M': { label: '3M', className: 'text-center', sort: '3M' },
  '4M': { label: '4M', className: 'text-center', sort: '4M' },
  '5M': { label: '5M', className: 'text-center', sort: '5M' },
  '6M': { label: '6M', className: 'text-center', sort: '6M' },
  '7M': { label: '7M', className: 'text-center', sort: '7M' },
  '8M': { label: '8M', className: 'text-center', sort: '8M' },
  '9M': { label: '9M', className: 'text-center', sort: '9M' },
  '10M': { label: '10M', className: 'text-center', sort: '10M' },
  '11M': { label: '11M', className: 'text-center', sort: '11M' },
  '12M': { label: '12M', className: 'text-center', sort: '12M' },
  '2Y': { label: '2Y', className: 'text-center', sort: '2Y' },
  '3Y': { label: '3Y', className: 'text-center', sort: '3Y' },
  '4Y': { label: '4Y', className: 'text-center', sort: '4Y' },
  '5Y': { label: '5Y', className: 'text-center', sort: '5Y' },
});

export const presenter = (data, index, actions, { isAccepting, maturingSummary }) => {
  const {
    unsolicitedRatesheetEntityId,
    issuer,
    ratesheet,
    unsolicitedRatesheetMaxBankAmount,
    unsolicitedRatesheetCustomMaturityDateRangeLimit,
  } = data;

  const isStale = !sameDayAsToday(ratesheet.updatedAt);

  return (
    <tr key={index}>
      <td className="issuer-name-column">
        <Tooltip id={`issuer-name-${issuer.code}`} tooltipComponent={issuer.name}>
          <span className="text-ellipsis">{issuer.name}</span>
        </Tooltip>
      </td>
      <td className="issuer-ratings-column">
        <span>{issuer.shortRating === 'Z' ? <FormattedMessage id="rfqUnrated" /> : issuer.shortRating}</span>
        <span>{issuer.longRating === 'Z' ? <FormattedMessage id="rfqUnrated" /> : issuer.longRating}</span>
      </td>
      <td className="updated-at-column">
        {isStale && (
          <Tooltip
            id={`stale-ratesheet-${issuer.code}`}
            tooltipComponent={
              <FormattedMessage
                id="unsolicitedRatesheetLastUpdatedAt"
                values={{
                  date: (
                    <FormattedDate
                      value={ratesheet.updatedAt}
                      day="numeric"
                      month="long"
                      year="numeric"
                      hour="numeric"
                      minute="numeric"
                    />
                  ),
                }}
              />
            }
          >
            <FaExclamationTriangle className="text-warning" />
          </Tooltip>
        )}
      </td>
      {ratesheet.rates.map(({ tenor, rate, isBestQuote }) => (
        <td
          className={classNames('trade-from-rate-column text-center', {
            'is-best-quote': isBestQuote && rate !== null,
          })}
          key={tenor}
        >
          <CreateTradeFromRatePopoverButton
            ratesheet={ratesheet}
            unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
            unsolicitedRatesheetCustomMaturityDateRangeLimit={unsolicitedRatesheetCustomMaturityDateRangeLimit}
            isAccepting={isAccepting}
            issuer={issuer}
            onCreateRfqClick={actions.onCreateRfqClick}
            onReinvestRfqClick={actions.onReinvestRfqClick}
            tenor={tenor}
            rate={rate}
            maturingSummary={maturingSummary}
            onSubmit={async ({
              allocationCode,
              interestPaid,
              principal,
              additionalFunds,
              rootTradeId,
              customMaturityDate,
              bankIssuerId,
            }) => {
              const reinvestmentProperties = rootTradeId && {
                additionalFunds: additionalFunds ? toFloat(additionalFunds) : 0,
                rootTradeId,
                principal: undefined,
              };

              return unsolicitedRatesheetEntityId
                ? actions.onAcceptBespokeRatesheetConfirm({
                    unsolicitedRatesheetEntityId,
                    allocationCode,
                    interestPaid,
                    rate,
                    principal: toFloat(principal),
                    tenor,
                    bankIssuerId,
                    customMaturityDate,
                    ...reinvestmentProperties,
                  })
                : actions.onAcceptDefaultRatesheetConfirm({
                    allocationCode,
                    interestPaid,
                    rate,
                    principal: toFloat(principal),
                    tenor,
                    bankIssuerId,
                    customMaturityDate,
                    ...reinvestmentProperties,
                  });
            }}
          />
        </td>
      ))}
    </tr>
  );
};
