import './tenors.scss';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Column } from 'react-display-flex';

import { EditableTenorItem } from './EditableTenorItem';
import { TenorItem } from './TenorItem';

export const isAcceptedOrReinvested = (status) =>
  ['accepted', 'reinvested', 'reinvestedOnBehalf', 'highlight'].includes(status);

const renderLoadingBoxes = ({ minimumTenorBoxes }) =>
  Array.from({ length: minimumTenorBoxes }).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Column key={index} className="tenor is-loading-skeleton" />
  ));

export const Tenors = ({
  ratesheet,
  status,
  selectedTenor,
  isLoading,
  disabled,
  readOnly,
  onClick,
  minimumTenorBoxes,
  onTenorItemChange,
  tenorItemComponent,
  tenorItemComponentProps,
  ...rest
}) => (
  <section
    aria-label="tenors grid"
    className={cx('tenors-grid', { disabled, editable: !!onTenorItemChange, 'read-only': readOnly })}
    {...rest}
  >
    {isLoading
      ? renderLoadingBoxes({ minimumTenorBoxes })
      : ratesheet &&
        ratesheet.rates &&
        ratesheet.rates.map(({ tenor, rate, originalRate, isCustom, ...otherTenorProps }) => {
          const tenorClassName = cx({
            active: selectedTenor === tenor,
            winner: selectedTenor === tenor && isAcceptedOrReinvested(status),
            declined: selectedTenor && selectedTenor !== tenor,
          });

          const props = {
            key: tenor,
            className: tenorClassName,
            onClick,
            tenor,
            rate,
            isCustom,
            ...otherTenorProps,
          };

          return onTenorItemChange ? (
            <EditableTenorItem {...props} onChange={onTenorItemChange} defaultRate={originalRate} />
          ) : (
            <TenorItem {...props} tenorItemComponent={tenorItemComponent} {...tenorItemComponentProps} />
          );
        })}
  </section>
);

Tenors.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  status: PropTypes.string,
  ratesheet: PropTypes.shape({
    updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number,
        tenor: PropTypes.string,
      }),
    ),
  }),
  selectedTenor: PropTypes.string,
  minimumTenorBoxes: PropTypes.number,
  onClick: PropTypes.func,
  onTenorItemChange: PropTypes.func,
  tenorItemComponentProps: PropTypes.object,
};

const defaultMinimumTenorBoxes = 16;

Tenors.defaultProps = {
  disabled: false,
  isLoading: false,
  minimumTenorBoxes: defaultMinimumTenorBoxes,
  ratesheet: undefined,
  selectedTenor: undefined,
  status: undefined,
};
