import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { SummaryItem } from '../../../components/common';
import { getMoneySymbol, Money } from '../../money';

const TotalizerComponent = ({ holding, currency, ...props }) => {
  const currencyValues = { currency: getMoneySymbol({ currency, short: true }) };

  return (
    <SummaryItem
      className="summary-item"
      top={<FormattedMessage id="overallValue" values={currencyValues} />}
      middle={<Money value={holding.summary.general.sum} short fraction={holding.summary.general.sum > 0 ? 2 : 0} />}
      bottom={
        <FormattedMessage
          tagName="span"
          id="portfolio.filter-investments-number"
          values={{ length: holding.summary.general.count }}
        />
      }
      isLoading={holding.summary.general.isFetching}
      {...props}
    />
  );
};

TotalizerComponent.propTypes = {
  holding: PropTypes.shape().isRequired,
  currency: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  holding: state.holding,
  currency: state.session.user.currency,
});

export const OverallValueTotalizer = connect(mapStateToProps)(TotalizerComponent);
