import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ResponsiveLine } from '@nivo/line';

import { nivoChartTheme } from '../../../../../../theme/nivo-chart-settings';
import {
  investorUltimateCurveChartColors,
  investorUltimateCurveChartLayers,
  investorUltimateCurveChartMargin,
  investorUltimateCurveChartSettings,
} from './investor-ultimate-curve-chart-settings';
import { InvestorUltimateCurvePointsLayer } from './InvestorUltimateCurvePointsLayer';

export const InvestorUltimateCurveChart = ({ data, investorUnsolicitedRatesheets, onPointClick, readOnly }) => {
  // Calculate min and max values from the data
  const rates = data.map((item) => item.rate);
  const minRate = Math.min(...rates);
  const maxRate = Math.max(...rates);

  // Calculate the range and padding
  const range = maxRate - minRate;
  const padding = Math.max(range * 0.1, 0.1); // Use at least 0.1% padding, or 10% of range if larger

  const yMin = minRate - padding;
  const yMax = maxRate + padding;

  return (
    <div className={classNames('rate-curve-chart', { 'is-disabled': readOnly })}>
      <ResponsiveLine
        data={[
          {
            id: 'Rate Curve',
            data: data.map(({ tenor, rate, issuers }) => ({
              x: tenor,
              y: rate,
              issuers,
            })),
          },
        ]}
        margin={investorUltimateCurveChartMargin}
        {...investorUltimateCurveChartSettings}
        axisLeft={{
          format: (value) => `${value.toFixed(2)}%`,
        }}
        yScale={{
          type: 'linear',
          min: yMin,
          max: yMax,
        }}
        colors={investorUltimateCurveChartColors}
        theme={nivoChartTheme}
        areaBaselineValue={yMin}
        layers={[
          ...investorUltimateCurveChartLayers,
          (props) => <InvestorUltimateCurvePointsLayer {...props} onPointClick={onPointClick} />,
        ]}
      />
    </div>
  );
};

InvestorUltimateCurveChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      tenor: PropTypes.string.isRequired,
      rate: PropTypes.number,
    }),
  ).isRequired,
  investorUnsolicitedRatesheets: PropTypes.arrayOf(
    PropTypes.shape({
      issuer: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      ratesheet: PropTypes.shape({
        rates: PropTypes.arrayOf(
          PropTypes.shape({
            tenor: PropTypes.string.isRequired,
            rate: PropTypes.number,
            isBestQuote: PropTypes.bool,
          }),
        ).isRequired,
      }).isRequired,
    }),
  ),
  onPointClick: PropTypes.func,
  readOnly: PropTypes.bool,
};
