import './control-center.scss';

import React, { useEffect, useRef, useState } from 'react';

import { Overlay, Popover } from 'react-bootstrap';
import { Column } from 'react-display-flex';
import { FaListAlt, FaRegListAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import useSWR from 'swr';

import {
  fetchTradesMaturingIfNeeded as fetchTradesMaturingIfNeededAction,
  fetchTradesSummaryIfNeeded as fetchTradesSummaryIfNeededAction,
} from '../../actions/holding/holding';
import { isBank } from '../../actions/session-selector';
import rooms from '../../api/socket/rooms';
import {
  getInvestorsUnsolicitedRatesheetsUrl,
  getInvestorUnsolicitedRatesheets,
} from '../../api/unsolicited-ratesheets/investor/get-investor-actionable-unsolicited-ratesheets';
import { ErrorBoundary } from '../../containers/app/ErrorBoundary';
import { holdingsTabKey, rfqsTabKey } from '../../containers/home/home';
import { MaturingTodayTotalizer, OutgoingRfqsTotalizer, OverallValueTotalizer } from '../../containers/home/Totalizers';
import { fetchIncomingDashboard as fetchIncomingDashboardAction } from '../../ducks/dashboard';
import { getIncomingPanelData } from '../../ducks/dashboard/selectors';
import { routes } from '../../routes';
import { PulseBlob, SummaryItem, Tooltip } from '../common';
import includeSocket, { socketEvents } from '../hoc/include-socket';

const isRfqOpened = (status, confirmed) => status === 'open' || (status === 'closed' && confirmed === false);

const containerPadding = 10;

export const ControlCenterNotificationsComponent = ({
  quotes,
  fetchTradesSummaryIfNeeded,
  fetchTradesMaturingIfNeeded,
  fetchIncomingDashboard,
  incoming,
  holding,
  isBank,
  on,
  off,
}) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const pulseBlobRef = useRef();

  useEffect(() => {
    fetchTradesSummaryIfNeeded();
  }, [fetchTradesSummaryIfNeeded]);

  const rfqs = quotes.rfqs || [];
  const openedRfqs = rfqs.filter(({ rfqStatus, rfqConfirmed }) => isRfqOpened(rfqStatus, rfqConfirmed));
  const quotesReceivedCount = openedRfqs.reduce((acc, rfq) => acc + rfq.quotesReceived, 0);

  const notifications = [];

  if (holding && holding.summary && holding.summary.general.count) {
    notifications.push(
      <OverallValueTotalizer
        key="overall-value-totalizer"
        onClick={() => {
          navigate(routes.portfolioRoot.rfqs, { state: { primaryTab: holdingsTabKey } });
          setShow(false);
        }}
      />,
    );
  }

  useEffect(() => {
    fetchTradesMaturingIfNeeded();
  }, [fetchTradesMaturingIfNeeded]);

  if (holding && holding.maturing && holding.maturing.sum > 0) {
    notifications.push(
      <MaturingTodayTotalizer
        key="maturing-today-totalizer"
        changeView={() => {
          navigate(routes.portfolioRoot.rfqs, { state: { primaryTab: rfqsTabKey } });
          setShow(false);
        }}
        highlight={false}
      />,
    );
  }

  if (quotesReceivedCount > 0) {
    notifications.push(
      <OutgoingRfqsTotalizer
        key="outgoing-rfqs-totalizer"
        changeView={() => {
          navigate(routes.holdings.quotes);
          setShow(false);
        }}
        highlight={false}
      />,
    );
  }

  const { data: investorUnsolicitedRatesheets } = useSWR(
    getInvestorsUnsolicitedRatesheetsUrl,
    getInvestorUnsolicitedRatesheets,
  );

  if (investorUnsolicitedRatesheets?.length) {
    notifications.push(
      <SummaryItem
        key="unsolicited-ratesheets-available"
        top={<FormattedMessage id="unsolicitedRatesheet" />}
        middle={investorUnsolicitedRatesheets?.length}
        bottom={
          <React.Fragment>
            <FormattedMessage id="unsolicitedRatesheetsAvailable" />
            <PulseBlob />
          </React.Fragment>
        }
        onClick={() => {
          navigate(routes.unsolicitedRatesheets.investor.base);
          setShow(false);
        }}
      />,
    );
  }

  useEffect(() => {
    if (!isBank) {
      return;
    }

    fetchIncomingDashboard();
  }, [fetchIncomingDashboard, isBank]);

  const hasPendingRfqs = isBank && incoming && incoming.summary && incoming.summary.pending.count;

  if (hasPendingRfqs) {
    notifications.push(
      <SummaryItem
        key="incoming-pending-rfqs"
        top={<FormattedMessage id="dashboard.incomingRfqs" />}
        middle={incoming.summary.pending.count}
        bottom={
          <React.Fragment>
            <FormattedMessage id="incomingPending" />
            <PulseBlob />
          </React.Fragment>
        }
        onClick={() => {
          navigate(routes.dashboardRfqs, { state: { primaryTab: rfqsTabKey } });
          setShow(false);
        }}
      />,
    );
  }

  const hasOngoingRfqs = isBank && incoming && incoming.summary && incoming.summary.ongoing.count;

  if (hasOngoingRfqs) {
    notifications.push(
      <SummaryItem
        key="incoming-ongoing-rfqs"
        top={<FormattedMessage id="dashboard.incomingRfqs" />}
        middle={incoming.summary.ongoing.count}
        bottom={
          <React.Fragment>
            <FormattedMessage id="incomingOngoing" />
            <PulseBlob />
          </React.Fragment>
        }
        onClick={() => {
          navigate(routes.dashboardRfqs, { state: { primaryTab: rfqsTabKey } });
          setShow(false);
        }}
      />,
    );
  }

  useEffect(() => {
    if (!isBank) {
      return;
    }

    on(socketEvents.rfqUpdated, fetchIncomingDashboard);

    return () => {
      off(socketEvents.rfqUpdated, fetchIncomingDashboard);
    };
  }, [on, off, isBank, fetchIncomingDashboard]);

  if (!notifications.length) {
    return (
      <span className="menu-notifications disabled">
        <Tooltip id="emptyNotifications" placement="bottom">
          <FaListAlt />
        </Tooltip>
      </span>
    );
  }

  const shouldPulse =
    investorUnsolicitedRatesheets?.length || holding?.maturing?.sum || quotesReceivedCount || hasPendingRfqs;

  return (
    <ErrorBoundary>
      <span className="menu-notifications" ref={pulseBlobRef}>
        <Overlay
          trigger="click"
          placement="bottom"
          target={pulseBlobRef?.current}
          rootClose
          show={show}
          onHide={() => setShow(false)}
          containerPadding={containerPadding}
        >
          <Popover
            className="light notifications-popover"
            title={
              <Column alignItemsStart>
                <FormattedMessage tagName="h4" id="controlCenter" />
              </Column>
            }
            placement="bottom"
          >
            {notifications}
          </Popover>
        </Overlay>
        <FaRegListAlt onClick={() => setShow(!show)} />
        {shouldPulse ? <PulseBlob onClick={() => setShow(!show)} /> : null}
      </span>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
  holding: state.holding,
  quotes: state.quotes,
  isBank: isBank(state),
  incoming: getIncomingPanelData(state),
});

export const ControlCenterNotifications = compose(
  connect(mapStateToProps, {
    fetchTradesSummaryIfNeeded: fetchTradesSummaryIfNeededAction,
    fetchTradesMaturingIfNeeded: fetchTradesMaturingIfNeededAction,
    fetchIncomingDashboard: fetchIncomingDashboardAction,
  }),
  includeSocket({ rooms: [rooms.rfq] }),
)(ControlCenterNotificationsComponent);
