export const investorUltimateCurveChartMargin = { top: 20, right: 20, bottom: 30, left: 100 };

export const investorUltimateCurveChartLayers = ['grid', 'axes', 'areas', 'lines'];

export const investorUltimateCurveChartColors = ['var(--primary-color)'];

export const investorUltimateCurveChartSettings = {
  curve: 'monotoneX',
  enableArea: true,
  enableGridX: false,
};
