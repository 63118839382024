import { cloneDeep } from 'lodash';

import {
  holdingDownloadCsvPayload,
  holdingFetchPayload,
  RESET_SUMMARY_FILTER,
  tradesMaturingFetchPayload,
  tradesSummaryFetchPayload,
  tradesSummaryFilterFetchPayload,
  UPDATE_HOLDING_FILTER,
  UPDATE_HOLDING_SELECTED_DATE,
  UPDATE_TRADE,
} from '../../actions/holding/holding';
import { UPDATE_PORTFOLIO_SELECTED_DATE } from '../../actions/portfolio';
import { generalAllocationCode } from '../../api/holding/codes';
import capitalize from '../../capitalize';
import { today } from '../../date';
import { sortByPropertyDesc } from '../../sort';

export const INITIAL_STATE = {
  hasInitiated: false,
  isFetching: false,
  didInvalidate: false,
  holding: null,
  lastUpdateAt: null,
  pagination: {
    activePage: 1,
    items: 0,
    limit: 10,
    maxButtons: 5,
    isFetching: false,
  },
  summary: {
    general: {
      isFetching: false,
      sum: 0,
      count: 0,
    },
    filter: null,
    capitalBasis: null,
    longTermComplianceOnly: false,
    commonAllocation: null,
  },
  maturing: {
    isFetching: false,
    maturingTodayCount: 0,
    maturingTodaySum: 0,
    notDealtWith: 0,
    maturingTodayConfirmed: 0,
  },
  filter: null,
  holdingsCsvFetching: false,
  availableFilters: {
    instrumentCode: ['All'],
    allocationCode: ['All'],
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  const silentFetch = action.state && action.state.portfolio && action.state.portfolio.silentFetch;

  switch (action.type) {
    case holdingFetchPayload.REQUEST:
      return {
        ...state,
        hasInitiated: true,
        isFetching: !silentFetch,
        didInvalidate: true,
        error: null,
      };
    case holdingFetchPayload.SUCCESS: {
      const limit = (action.params && action.params.limit) || state.pagination.limit;

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        pagination: {
          ...state.pagination,
          activePage: (action.params && action.params.page) || 1,
          limit,
        },
        filter: {
          ...state.filter,
        },
        holding: action.holding,
        error: null,
        lastUpdateAt: today(),
      };
    }
    case holdingFetchPayload.FAIL:
      return {
        ...state,
        holding: null,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case holdingDownloadCsvPayload.REQUEST:
      return {
        ...state,
        holdingsCsvFetching: true,
      };
    case holdingDownloadCsvPayload.SUCCESS:
      return {
        ...state,
        holdingsCsvFetching: false,
      };
    case holdingDownloadCsvPayload.FAIL:
      return {
        ...state,
        holdingsCsvFetching: false,
      };
    case UPDATE_HOLDING_SELECTED_DATE:
      return {
        ...state,
        isFetching: true,
        pagination: {
          ...state.pagination,
          activePage: 1,
        },
        filter: {
          ...state.filter,
          date: action.date,
        },
        error: null,
      };
    case UPDATE_TRADE: {
      const { tradeId, ...payload } = action.holding;
      const holding = cloneDeep(state.holding);
      const index = holding.trades.findIndex((trade) => trade.id === tradeId);

      if (index >= 0) {
        holding.trades[index] = { ...holding.trades[index], ...payload };
      }

      return { ...state, holding };
    }
    case UPDATE_HOLDING_FILTER:
      return {
        ...state,
        filter: {
          ...action.newFilter,
        },
        error: null,
      };
    case tradesSummaryFetchPayload.REQUEST:
      return {
        ...state,
        summary: {
          ...state.summary,
          general: {
            isFetching: true,
          },
        },
      };
    case tradesSummaryFetchPayload.SUCCESS: {
      const { summary } = action;
      const [commonAllocation] = summary.allocationCodes.sort((prev, next) =>
        sortByPropertyDesc('tradesCount', prev, next),
      );
      const newSummaryState = {
        general: {
          isFetching: false,
          sum: summary.investmentSum,
          count: summary.investmentCount,
          wam: summary.wam ? summary.wam.toFixed(2) : 0,
          way: summary.way ? summary.way.toFixed(2) : 0,
        },
        capitalBasis: summary.capitalBasis,
        longTermComplianceOnly: summary.longTermComplianceOnly,
        commonAllocation: commonAllocation || generalAllocationCode,
      };

      const instrumentCodes = summary.instrumentCodes || [];

      const allocationCodes = summary.allocationCodes.length
        ? summary.allocationCodes.filter(({ tradesCount }) => tradesCount > 0)
        : [];

      return {
        ...state,
        summary: {
          ...state.summary,
          ...newSummaryState,
        },
        availableFilters: {
          instrumentCode: [
            'All',
            ...instrumentCodes
              .map((instrument) => (instrument.code.toLowerCase() === 'frtd' ? 'FRTD' : capitalize(instrument.code)))
              .sort(),
          ],
          allocationCode: ['All', ...allocationCodes.map(({ code }) => code).sort()],
        },
        error: null,
      };
    }
    case tradesSummaryFetchPayload.FAIL:
      return {
        ...state,
        summary: {
          ...state.summary,
          general: {
            isFetching: false,
          },
        },
        error: action.error,
      };
    case tradesSummaryFilterFetchPayload.REQUEST:
      return {
        ...state,
        summary: {
          ...state.summary,
          filter: {
            ...state.summary.filter,
            isFetching: !silentFetch,
          },
        },
      };
    case tradesSummaryFilterFetchPayload.SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          filter: {
            isFetching: false,
            sum: action.summary.investmentSum,
            count: action.summary.investmentCount,
            wam: action.summary.wam ? action.summary.wam.toFixed(2) : 0,
            way: action.summary.way ? action.summary.way.toFixed(2) : 0,
          },
        },
        error: null,
      };
    case RESET_SUMMARY_FILTER:
      return {
        ...state,
        summary: {
          ...state.summary,
          filter: null,
        },
      };
    case tradesSummaryFilterFetchPayload.FAIL:
      return {
        ...state,
        summary: {
          ...state.summary,
          filter: {
            isFetching: false,
          },
        },
        error: action.error,
      };
    case tradesMaturingFetchPayload.REQUEST:
      return {
        ...state,
        maturing: {
          ...state.maturing,
          isFetching: !action.silentFetch,
        },
      };
    case tradesMaturingFetchPayload.SUCCESS:
      return {
        ...state,
        maturing: {
          isFetching: false,
          sum: action.maturing.maturingTodaySum,
          count: action.maturing.maturingTodayCount,
          notDealtWith: action.maturing.maturingTodayNotDealtWith,
          confirmed: action.maturing.maturingTodayConfirmed,
        },
        error: null,
      };
    case tradesMaturingFetchPayload.FAIL:
      return {
        ...state,
        maturing: {
          isFetching: false,
        },
        error: action.error,
      };
    case UPDATE_PORTFOLIO_SELECTED_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
        },
        error: null,
      };
    default:
      return state;
  }
}
